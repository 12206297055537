import { gql } from '@apollo/client';

export const searchMarketplaceSpacesQuery = gql`
  query MarketplaceSearchV2($query: String!) {
    marketplaceSearch(query: $query) {
      center
      spaces {
        id
        name
        description
        displayInDirectory
        seated
        standing
        slug
        pathname
        images {
          id
          url
          filename
        }
        venue {
          id
          slug
          name
        }
      }
      venues {
        id
        name
        slug
        address {
          id
          streetAddress1
          streetAddress2
          city
          state
          latitude
          longitude
        }
        logo
        profileBanner
        images {
          id
          url
          filename
        }
      }
    }
  }
`;
