import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { MenuComponents as Menu } from '@pv/common/components';

import { PageContainer } from '../ExpressBook/styledComponents';
import { NavigationBar } from '../ExpressBook/NavigationBar';

import { VENUE_MENU_QUERY } from '../ExpressBook/graphql';

import { FooterBar } from '../../components/FooterBar';
import { PreviewMenuItemModal } from './PreviewMenuItemModal';
const { VenueMenu } = Menu;

export const ViewOnlyMenu = () => {
  const params = useParams();

  const { venueSlug } = params;

  const pageContainerRef = React.useRef(null);
  const navBarRef = React.useRef<HTMLElement>(null);

  const { data: menuData } = useQuery(VENUE_MENU_QUERY, {
    variables: { venueSlug },
  });

  const [selectedMenuItem, setSelectedMenuItem] =
    useState<Menu.Types.MenuItem | null>(null);
  const isMenuItemModalOpen = !!selectedMenuItem;

  const handleCloseMenuItemModal = () => {
    setSelectedMenuItem(null);
  };

  const handleMenuItemClick = (menuItem: Menu.Types.MenuItem) => {
    setSelectedMenuItem(menuItem);
  };
  const venue = menuData?.marketplaceVenue || null;
  const menuItems = venue?.menu?.menuItems || [];

  return (
    <>
      <NavigationBar ref={navBarRef}>
        <Button
          variant="contained"
          color="secondary"
          href={`/venues/${venueSlug}`}
        >
          Inquire Now
        </Button>
      </NavigationBar>
      <PageContainer ref={pageContainerRef}>
        <Typography
          variant="h1"
          sx={{
            textAlign: 'center',
            fontSize: '30px',
            fontWeight: '700',
            lineHeight: '34.5px',
          }}
        >
          Menu
        </Typography>
        {menuItems && (
          <VenueMenu
            menuItems={menuItems}
            onMenuItemClick={handleMenuItemClick}
            showOptions={false}
            showExpander={false}
            numColumns={2}
            headerSize="large"
            selectedTabColor="secondary"
            menuCategoryHeaderGap={24}
          />
        )}
        {selectedMenuItem && (
          <PreviewMenuItemModal
            open={isMenuItemModalOpen}
            menuItem={selectedMenuItem}
            onClose={handleCloseMenuItemModal}
          />
        )}
        <FooterBar />
      </PageContainer>
    </>
  );
};
