import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

import { PVGray } from '@pv/common/colors';

import type { Space, Spaces } from '../types/models/space_types';
import { SpacePreview } from './SpacePreview';

/*
 * Types.
 */

interface SpacesShelfProps {
  spaces: Spaces;
  previewWidth: string;
  gridTemplateColumns: { [key: string]: string };
}

/*
 * Components.
 */

export const SpacesShelf: FC<SpacesShelfProps> = ({
  spaces,
  previewWidth,
  gridTemplateColumns,
}: SpacesShelfProps) => (
  <Stack direction="column" gap="24px">
    <Typography
      variant="h2"
      sx={{
        fontSize: '16px',
        lineHeight: '150%',
        fontWeight: 400,
      }}
    >
      <b>{spaces.length ?? 0}</b> Event Spaces Available
    </Typography>
    {!spaces.length ? (
      <Stack
        direction="column"
        gap="18px"
        alignItems="center"
        justifyContent="center"
        sx={{ paddingTop: '20vh' }}
      >
        <img alt="No spaces available" src="/bus.png" />
        <Typography variant="body2" color={PVGray}>
          No spaces available for selected date or time
        </Typography>
      </Stack>
    ) : (
      <Box
        sx={{
          display: 'grid',
          gap: '24px',
          gridTemplateColumns: gridTemplateColumns,
        }}
      >
        {spaces.map((space: Space) => (
          <SpacePreview key={space.slug} space={space} />
        ))}
      </Box>
    )}
  </Stack>
);
