import React from 'react';
import { Typography } from '@mui/material';
import { EventRequestHeaderContainer } from './styledComponents';

interface Props {
  headerText: string;
}

export const EventRequestPageHeader = ({ headerText }: Props) => {
  return (
    <EventRequestHeaderContainer>
      <Typography
        variant="h4"
        sx={{
          fontSize: '30px',
          fontWeight: '700',
          lineHeight: '34.5px',
        }}
      >
        {headerText}
      </Typography>
    </EventRequestHeaderContainer>
  );
};
