import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

import { NavigationBar } from './NavigationBar';

export const SearchPageLayout = () => (
  <Box sx={{ backgroundColor: 'white' }}>
    <NavigationBar />
    <Box>
      <Outlet />
    </Box>
  </Box>
);
