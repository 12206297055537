import React from 'react';
import { SearchResultsProvider } from './providers/SearchResultsProvider';
import { SearchResults } from './components/SearchResults';
import { FilteredSearchResultsProvider } from './providers/FilteredSearchResultsProvider';
import { MappableVenuesProvider } from './providers/MappableVenuesProvider';

export const SearchResultsPage = () => {
  return (
    <SearchResultsProvider>
      <FilteredSearchResultsProvider>
        <MappableVenuesProvider>
          <SearchResults />
        </MappableVenuesProvider>
      </FilteredSearchResultsProvider>
    </SearchResultsProvider>
  );
};
