import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';

import type { IconAssetProps } from '../../types/components/icon_types';

export const Filters: FC<IconAssetProps> = ({ size, sx }: IconAssetProps) => {
  const style = size ? { width: `${size}px` } : {};

  return (
    <SvgIcon sx={{ ...style, ...sx }} viewBox="0 0 20 20">
      <circle
        id="Ellipse 41"
        cx="14"
        cy="14"
        r="3"
        stroke="white"
        strokeWidth="2"
        fill="none"
      />
      <ellipse
        id="Ellipse 42"
        cx="6"
        cy="5.99998"
        rx="3"
        ry="3"
        transform="rotate(-180 6 5.99998)"
        stroke="white"
        strokeWidth="2"
        fill="none"
      />
      <path
        id="Vector 20"
        d="M4 14H11"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 21"
        d="M16 5.99998L9 5.99998"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </SvgIcon>
  );
};
