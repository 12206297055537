import React, { FC, ReactNode, memo } from 'react';
import { LoadScript } from '@react-google-maps/api';

/*
 * Types.
 */

interface GoogleApiProviderProps {
  children: ReactNode;
}

/*
 * Constants.
 */

const googleApiKey =
  process.env.REACT_APP_GOOGLE_API_KEY ??
  'AIzaSyCABoBajwP5JARmturb8sgkTyCMft3O3KI';

/*
 * Components.
 */

export const GoogleApiProvider: FC<GoogleApiProviderProps> = ({
  children,
}: GoogleApiProviderProps) => {
  const [isLoaded, setIsLoaded] = React.useState(false);

  return (
    <LoadScript
      googleMapsApiKey={googleApiKey}
      id="marketplace-google-location-selector"
      version="weekly"
      libraries={['places']}
    >
      {children}
    </LoadScript>
  );
};

export default memo(GoogleApiProvider);
