import React from 'react';
import { MenuComponents as Menu } from '@pv/common/components';

const { VenueMenu } = Menu;

interface Props {
  venue: Menu.Types.Venue | null;
  onClickMenuItem?: (menuItem: Menu.Types.MenuItem) => void;
}
export const ExpressBookMenu = ({ venue, onClickMenuItem }: Props) => {
  if (!venue) {
    return null;
  }

  const menuItems = venue.menu?.menuItems || [];

  return (
    <VenueMenu
      menuItems={menuItems}
      onMenuItemActionClick={onClickMenuItem}
      onMenuItemClick={onClickMenuItem}
      showOptions={false}
      showExpander={false}
      numColumns={2}
      headerSize="large"
      selectedTabColor="secondary"
      menuCategoryHeaderGap={24}
    />
  );
};
