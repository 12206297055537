import React from 'react';
import { useParams } from 'react-router-dom';
import { Button, Divider, Stack, Typography } from '@mui/material';
import { NavigationBar } from '../NavigationBar';
import { FooterBar } from '../../../components/FooterBar';
import { PageContainer } from '../styledComponents';
import { StepCounter } from '../StepCounter';
import { ItsAlmostTimeToParty } from '../ItsAlmostTimeToParty';

export const ExpressBookConfirmationPage = () => {
  const { venueSlug } = useParams();

  return (
    <>
      <NavigationBar />
      <PageContainer>
        <StepCounter activeStepIndex={3} />
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          <ItsAlmostTimeToParty />
          <Typography variant="body1">
            The venue has up to <strong> 24 hours </strong> to respond to your
            event request.
          </Typography>
          <Typography variant="body1">
            You will receive an email when they respond.
          </Typography>
          <Button
            sx={{ marginTop: '24px' }}
            variant="outlined"
            color="secondary"
            href={`/venues/${venueSlug}`}
          >
            Return to Venue
          </Button>
        </Stack>
        <div style={{ flexGrow: 1 }} />
        <div>
          <Divider />
          <FooterBar />
        </div>
      </PageContainer>
    </>
  );
};
