import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { MenuComponents as Menu } from '@pv/common/components';
import { Contract, ContractItem } from '../types';
import { StepCounter } from '../StepCounter';
import { PageContainer } from '../styledComponents';
import { NavigationBar } from '../NavigationBar';

import {
  CONTRACT_QUERY,
  VENUE_MENU_QUERY,
  CREATE_CONTRACT_ITEM_MUTATION,
  UPDATE_CONTRACT_ITEM_MUTATION,
  REMOVE_CONTRACT_ITEM_MUTATION,
} from '../graphql';
import { OrderSummary } from './OrderSummary';
import { ExpressBookMenu } from './ExpressBookMenu';
import { SelectOptionsModal } from './SelectOptionsModal';
import { getInitialQuantity } from '../utils';

import { SuccessIndicator } from './SuccessIndicator';
import { FooterBar } from '../../../components/FooterBar';
import { CartButton } from './CartButton';

export const SelectMenuPage = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { venueSlug, spaceSlug, eventSlug } = params;
  const [orderSummaryOpen, setOrderSummaryOpen] = useState(false);
  const [contract, setContract] = useState<Contract>({} as Contract);
  const [openSelectOptionsModal, setOpenSelectOptionsModal] = useState(false);
  const [selectedMenuItem, setSelectedMenuItem] =
    useState<Menu.Types.MenuItem | null>(null);
  const [selectedContractItem, setSelectedContractItem] =
    useState<ContractItem | null>(null);
  const [successIndicatorOpen, setSuccessIndicatorOpen] = useState(false);
  const [recentlyAddedContractItem, setRecentlyAddedContractItem] =
    useState<ContractItem | null>(null);
  const pageContainerRef = React.useRef(null);
  const navBarRef = React.useRef<HTMLElement>(null);

  const { data: menuData } = useQuery(VENUE_MENU_QUERY, {
    variables: { venueSlug },
  });

  const { data: eventData } = useQuery(CONTRACT_QUERY, {
    variables: { eventSlug },
    onCompleted: (data) => {
      setContract(data.marketplaceEvent.contract);
      if (data.marketplaceEvent.expressBookRequest.status !== 'incomplete') {
        navigate(
          `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/success`,
        );
      }
    },
  });

  const event = eventData?.marketplaceEvent;

  const [createContractItem] = useMutation(CREATE_CONTRACT_ITEM_MUTATION, {
    onCompleted: (data: any) => {
      if (data.createContractItem?.contract) {
        setContract(data.createContractItem.contract);
      }
      setRecentlyAddedContractItem(data.createContractItem?.contractItem);
      setSuccessIndicatorOpen(true);
    },
  });

  const [updateContractItem] = useMutation(UPDATE_CONTRACT_ITEM_MUTATION, {
    onCompleted: (data: any) => {
      if (data.updateExpressBookItem?.contract) {
        setContract(data.updateExpressBookItem.contract);
      }
    },
  });

  const [removeContractItem] = useMutation(REMOVE_CONTRACT_ITEM_MUTATION, {
    onCompleted: (data: any) => {
      if (data.removeContractItem?.contract) {
        setContract(data.removeContractItem.contract);
      }
    },
  });

  const onClose = () => {
    setOrderSummaryOpen(false);
  };

  const handleAddOrUpdateMenuItem = (contractItem: ContractItem) => {
    if (contractItem.id) {
      const input = {
        id: contractItem.id,
        quantity: contractItem.quantity,
        addonIds: contractItem.addonIds,
        optionIds: contractItem.optionIds,
      };
      const variables = { input };
      updateContractItem({ variables });
      return;
    } else {
      const input = { ...contractItem, contractId: contract.id };
      const variables = { input };
      createContractItem({ variables });
    }
  };

  const handleRemoveContractItem = (contractItemId: string) => {
    const input = { id: contractItemId };
    const variables = { input };
    removeContractItem({ variables });
  };

  const handleMenuItemClick = React.useMemo(() => {
    return (menuItem: Menu.Types.MenuItem) => {
      const potentialContractItem = {
        ...Menu.Utils.createContractItemFromMenuItem(menuItem),
        quantity: getInitialQuantity(
          menuItem.unit,
          event.groupSize,
          event.duration,
        ),
        addonIds: [],
        optionIds: [],
        optionQuantities: {},
        addonQuantities: {}
      };
      setSelectedMenuItem(menuItem);
      setSelectedContractItem(potentialContractItem);
      setOpenSelectOptionsModal(true);
    };
  }, [event]);

  const handleCartItemEdit = (contractItem: ContractItem) => {
    setSelectedMenuItem(null);
    setSelectedContractItem(contractItem);
    setOpenSelectOptionsModal(true);
  };

  const handleCloseSelectOptionsModal = () => {
    setSelectedContractItem(null);
    setSelectedMenuItem(null);
    setOpenSelectOptionsModal(false);
  };

  const handleClickCheckout = async () => {
    const checkoutUrl = `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/${eventSlug}/checkout`;
    navigate(checkoutUrl);
  };

  return (
    <>
      <NavigationBar ref={navBarRef}>
        <CartButton
          contract={contract}
          onClick={() => setOrderSummaryOpen(true)}
        />
      </NavigationBar>
      <PageContainer ref={pageContainerRef}>
        <StepCounter activeStepIndex={1} />
        {menuData && eventData && (
          <ExpressBookMenu
            venue={menuData.marketplaceVenue}
            onClickMenuItem={handleMenuItemClick}
          />
        )}
        {contract && (
          <OrderSummary
            open={orderSummaryOpen}
            onClose={onClose}
            contract={contract}
            onClickEdit={handleCartItemEdit}
            onClickRemove={handleRemoveContractItem}
            onClickCheckout={handleClickCheckout}
          />
        )}
        {selectedContractItem && (
          <SelectOptionsModal
            open={openSelectOptionsModal}
            image={selectedContractItem.image || selectedMenuItem?.image}
            contractItem={selectedContractItem}
            onSubmit={handleAddOrUpdateMenuItem}
            onClose={handleCloseSelectOptionsModal}
          />
        )}
        {recentlyAddedContractItem && (
          <SuccessIndicator
            open={successIndicatorOpen}
            anchorEl={navBarRef.current}
            onViewCartClick={() => {
              setOrderSummaryOpen(true);
              setSuccessIndicatorOpen(false);
            }}
            onClose={() => {
              setSuccessIndicatorOpen(false);
              setRecentlyAddedContractItem(null);
            }}
            contractItem={recentlyAddedContractItem}
          />
        )}
        <FooterBar />
      </PageContainer>
    </>
  );
};
