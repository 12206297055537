import React, { useLayoutEffect, useMemo } from 'react';
import {
  Box,
  Container,
  Divider as MuiDivider,
  Typography,
  Stack,
  Link,
  Button,
} from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import { useParams, useLocation } from 'react-router';
import { Navigate } from 'react-router-dom';
import { User } from 'react-feather';
import { MapPin } from 'react-feather';

import { LoadingIndicator } from '@pv/common/components';
import { PVBlack } from '@pv/common/colors';

import { Image, ImageCarousel } from '../../../components/ImageCarousel';
import { GoogleMap } from '../../../components/GoogleMap';
import { buildMappableAddressCoord } from '../../../helpers/api/addresses';
import { fetchAvailableSpaces } from '../../../helpers/api/graphql';
import type { Space } from '../../../types/models/space_types';
import type { Venue } from '../../../types/models/venue_types';
import { VenueLocationDetailsLink } from '../../../components/VenueLocationDetailsLink';
import { SpacePreview } from '../../../components/SpacePreview';
import { Icon } from '../../../components/Icon';
import { AvailabilityForm } from './AvailabilityForm';
import { ContactUsButton } from '../../../components/ContactUsButton';
import { ProfileFooter } from '../../../components/ProfileFooter';
import { VenueSeoMarkup } from '../VenueSeoMarkup';

const VENUE_QUERY = gql`
  query SpacePageVenueQuery($slug: String!) {
    marketplaceVenue(slug: $slug) {
      id
      slug
      name
      description
      address {
        id
        streetAddress1
        streetAddress2
        city
        state
        latitude
        longitude
      }
      minGroupSize
      helloLeadFormUrl
      images {
        id
        url
      }
      expressBookSettings {
        id
        minLeadTimeDays
        maxLeadTimeDays
        minEventDurationHours
        maxEventDurationHours
      }
      availabilityEnabled
      hoursOfOperation {
        id
        dayOfWeek
        isOpen
        startTime
        endTime
      }
      stripeAccount {
        id
        stripeAccountId
      }
      menu {
        id
        menuItems(displayOnExpressBook: true) {
          id
        }
      }
      defaultPaymentProvider
    }
  }
`;

const SPACE_QUERY = gql`
  query SpacePageSpaceQuery($slug: String!) {
    marketplaceSpace(slug: $slug) {
      id
      slug
      name
      description
      seated
      standing
      images {
        id
        url
      }
      expressBookEnabled
    }
  }
`;

const Wrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return <Container>{children}</Container>;
};

const Layout = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      display: 'grid',
      gap: '0 48px',
      gridTemplateAreas: {
        xs: '"header" "aside" "body"',
        md: '"header" "body" "aside"',
      },
      gridTemplateColumns: {
        xs: 'auto',
        md: 'repeat(2, 1fr)',
        lg: 'auto auto 384px',
      },
      gridTemplateRows: {
        xs: 'auto auto 1fr',
        md: 'auto 1fr',
      },
      padding: {
        md: '0 32px',
      },
    }}
  >
    {children}
  </Box>
);

const Header = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      gridArea: 'header',
      gridColumnStart: 1,
      gridColumnEnd: { xs: 1, lg: 3 },
    }}
  >
    {children}
  </Box>
);

const Body = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      gridArea: 'body',
      gridColumn: 1,
      gridColumnEnd: { xs: 1, lg: 3 },
    }}
  >
    {children}
  </Box>
);

const Aside = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      gridArea: 'aside',
      gridColumn: { xs: 1, md: 2, lg: 3 },
      gridRowStart: { xs: 2, md: 1 },
      gridRowEnd: { xs: 0, md: 0 },
    }}
  >
    {children}
  </Box>
);

const Divider = () => <MuiDivider sx={{ marginBottom: '24px' }} />;

const SectionHeader = ({ children }: { children: React.ReactNode }) => (
  <Typography
    variant="h2"
    sx={{
      fontWeight: '700',
      fontSize: '24px',
      lineHeight: '133.4%',
      marginBottom: '36px',
    }}
  >
    {children}
  </Typography>
);

const SpaceName = ({ space }: { space: Space }) => (
  <Typography
    variant="h1"
    sx={{
      fontSize: '50px',
      lineHeight: '60px',
      marginBottom: '16px',
      fontFamily: 'Gazpacho',
      fontWeight: 'bold',
    }}
  >
    {space.name}
  </Typography>
);

const VenueInfo = ({ venue }: { venue: Venue }) => (
  <Stack spacing={2} sx={{ marginBottom: '24px' }}>
    <VenueLocationDetailsLink
      venueName={venue.name}
      venueSlug={venue.slug}
      venueAddress={venue.address?.city}
      fontSize="20px"
      venueNameColor={PVBlack}
      venueAddressColor={PVBlack}
      boldName
    />
    {venue?.address && (
      <Stack spacing={1} direction="row">
        <MapPin />
        <address>
          <Typography sx={{ fontStyle: 'normal' }}>
            {[
              venue.address.streetAddress1,
              venue.address.streetAddress2,
              venue.address.city,
              venue.address.state,
            ]
              .filter((v) => !!v)
              .join(', ')}
          </Typography>
        </address>
      </Stack>
    )}
  </Stack>
);

const GroupInfo = ({ space, venue }: { space: Space; venue: Venue }) => (
  <Stack spacing={4} direction="row" sx={{ marginBottom: '24px' }}>
    {!!space.seated && (
      <Stack spacing={2} direction="row">
        <Icon name="chair" />
        <Typography>{space.seated} Seated</Typography>
      </Stack>
    )}
    {!!space.standing && (
      <Stack spacing={2} direction="row">
        <User />
        <Typography>{space.standing} Standing</Typography>
      </Stack>
    )}
    {!!venue.minGroupSize && (
      <Typography>Minimum {venue.minGroupSize} Guests</Typography>
    )}
  </Stack>
);

const TextSection = ({ body, title }: { body: string; title: string }) => (
  <Box sx={{ marginBottom: '24px' }}>
    <SectionHeader>{title}</SectionHeader>
    <Typography sx={{ whiteSpace: 'pre-wrap' }}>{body}</Typography>
  </Box>
);

const VenueLocation = ({ venue }: { venue: Venue }) => {
  const venuePosition = buildMappableAddressCoord(venue.address);
  const mappableVenue = {
    key: venue.slug,
    name: venue.name,
    position: venuePosition,
  };

  return (
    <Box sx={{ marginBottom: '24px' }}>
      <SectionHeader>Location</SectionHeader>
      <GoogleMap
        center={venuePosition}
        mapStyle={{
          width: '100%',
          height: '320px',
          borderRadius: '12px',
        }}
        mappableItems={[mappableVenue]}
      />
    </Box>
  );
};

const OtherSpaces = ({ spaces }: { spaces: Space[] }) => (
  <>
    <SectionHeader>Other Spaces</SectionHeader>
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: {
          xs: '1fr',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(3, 1fr)',
        },
        gridAutoRows: '1fr',
        gap: '16px',
      }}
    >
      {spaces.map((space) => (
        <SpacePreview space={space} key={space.pathname} />
      ))}
    </Box>
  </>
);

const Availability = ({ children }: { children: React.ReactNode }) => (
  <Box
    sx={{
      padding: '24px',
      borderRadius: '16px',
      border: '1px solid rgba(0,0,0,0.1)',
      boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.08)',
    }}
  >
    <Box marginBottom={'24px'}>
      <Typography
        variant="h2"
        sx={{
          fontWeight: '700',
          fontSize: '24px',
          lineHeight: '133.4%',
        }}
      >
        Check Availability
      </Typography>
    </Box>
    {children}
  </Box>
);

export const SpacePage = () => {
  const { spaceSlug, venueSlug } = useParams();

  const {
    data: fetchVenueResponse,
    loading: venueQueryLoading,
    error: venueQueryError,
  } = useQuery(VENUE_QUERY, {
    variables: { slug: venueSlug },
    skip: !venueSlug,
  });

  const venue = fetchVenueResponse?.marketplaceVenue;

  const {
    data: fetchSpaceResponse,
    loading: spaceQueryLoading,
    error: spaceQueryError,
  } = useQuery(SPACE_QUERY, {
    variables: {
      slug: spaceSlug,
    },
    skip: !spaceSlug,
  });

  const space = fetchSpaceResponse?.marketplaceSpace;

  const { data: fetchAvailableSpacesResponse } = useQuery(
    fetchAvailableSpaces,
    {
      variables: {
        venueSlug,
      },
      skip: !venueSlug,
    },
  );

  const images = useMemo(() => {
    let images: { url: string }[] = [];

    if (space) {
      images = [...images, ...space.images];
    }

    if (venue) {
      images = [...images, ...venue.images];
    }

    if (!images.length) {
      images = [{ url: '/default_space_image.png' }];
    }

    return images;
  }, [space, venue]);

  const availableSpaces = fetchAvailableSpacesResponse?.availableSpaces;

  if (spaceQueryLoading || venueQueryLoading) {
    return <LoadingIndicator />;
  }

  const isSpaceNotFoundError =
    spaceQueryError?.graphQLErrors[0].message === 'No space found';
  const isVenueNotFoundError =
    venueQueryError?.graphQLErrors[0].message === 'No venue found';

  if (isSpaceNotFoundError || isVenueNotFoundError) {
    return <Navigate to="/not-found" replace={false} />;
  }

  return (
    <Wrapper>
      <VenueSeoMarkup venueSlug={venue.slug} />
      <main>
        <Box sx={{ marginBottom: '36px' }}>
          {space && venue && (
            <ImageCarousel>
              {images.map((image: { url: string }) => (
                <Image
                  sx={{ height: { xs: '300px', sm: '360px', md: '600px' } }}
                  src={image.url}
                  alt=""
                  key={image.url}
                />
              ))}
            </ImageCarousel>
          )}
        </Box>
        <Box sx={{ marginBottom: '48px' }}>
          <Layout>
            <Header>
              {space && <SpaceName space={space} />}
              {venue && <VenueInfo venue={venue} />}
              <Divider />
              {space && venue && <GroupInfo space={space} venue={venue} />}
            </Header>

            <Body>
              <Divider />
              {space && (
                <TextSection body={space.description} title="Space details" />
              )}
              {venue?.description && (
                <>
                  <Divider />
                  <TextSection
                    body={venue.description}
                    title="About the venue"
                  />
                </>
              )}
              {venue?.address && (
                <>
                  <Divider />
                  <VenueLocation venue={venue} />
                </>
              )}
            </Body>
            <Aside>
              <Box sx={{ display: { md: 'none' } }}>
                <Divider />
              </Box>
              {space && venue && (
                <Stack spacing={3}>
                  {venue.availabilityEnabled && (
                    <Availability>
                      <AvailabilityForm space={space} venue={venue} />
                    </Availability>
                  )}
                  <ContactUsButton leadFormUrl={venue.helloLeadFormUrl} />

                  {venue.menu?.menuItems.length > 0 && (
                    <Button
                      variant="outlined"
                      color="inherit"
                      startIcon={<Icon name="menu" />}
                      sx={{ height: '50px', fontSize: '16px' }}
                      href={`/venues/${venue.slug}/menu`}
                    >
                      View Menu
                    </Button>
                  )}
                </Stack>
              )}
            </Aside>
          </Layout>
          {space && availableSpaces?.length > 0 && (
            <Box sx={{ padding: { md: '0 32px' } }}>
              <Divider />
              <OtherSpaces
                spaces={availableSpaces.filter(
                  (s: Space) => s.slug !== space.slug,
                )}
              />
            </Box>
          )}
        </Box>
      </main>
      <footer>
        <Divider />
        <ProfileFooter />
      </footer>
    </Wrapper>
  );
};
