import React from 'react';
import { Link } from '@mui/material';

import { Link as RouterLink } from 'react-router-dom';

export const MarketplaceHomePageLink = () => {
  return (
    <Link sx={{ height: '32px' }} href="/">
      <img
        src="/pv-logo-marketplace.png"
        alt="Perfect Venue Logo"
        height={'32px'}
      />
    </Link>
  );
};
