import React from 'react';
import {
  Box,
  Link,
  Typography,
  TextField,
  InputAdornment,
} from '@mui/material';
import { SectionHeader, PinkInfoBox } from './styledComponents';

interface SignProposalProps {
  signature: string;
  setSignature: (value: string) => void;
  setShowPolicyModal: (value: boolean) => void;
}

export const SignProposal = ({
  signature,
  setSignature,
  setShowPolicyModal,
}: SignProposalProps) => {
  return (
    <>
      <SectionHeader>Sign Proposal</SectionHeader>
      <PinkInfoBox>
        <Box
          sx={{
            position: 'relative',
          }}
        >
          <img src="/clock-cal.svg" alt="" />
        </Box>
        <Box>
          <Typography variant="body1">
            <strong style={{ fontWeight: 500 }}>
              Your reservation won’t be confirmed until the Venue accepts your
              request (within 24 hours).
            </strong>{' '}
            You won’t be charged until then.
          </Typography>
        </Box>
      </PinkInfoBox>
      <TextField
        InputProps={{
          style: { fontFamily: 'Dancing Script', fontSize: 30 },
          startAdornment: <InputAdornment position="start">X</InputAdornment>,
        }}
        placeholder="Sign Here..."
        variant="standard"
        required
        name="signature"
        aria-label="Signature"
        value={signature}
        onChange={(e) => setSignature(e.target.value)}
      />
      <Typography variant="body2" sx={{}}>
        By signing the agreement, you agree to the{' '}
        <span>
          <Link
            component="button"
            variant="body2"
            onClick={() => setShowPolicyModal(true)}
            sx={{
              verticalAlign: 'baseline',
            }}
          >
            Venue's Policies
          </Link>
        </span>
        .
      </Typography>
    </>
  );
};
