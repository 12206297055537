import React from 'react';

import { styled } from '@mui/material/styles';
import { Typography, Box } from '@mui/material';

export const SectionHeader = ({ children }: { children: React.ReactNode }) => {
  return (
    <Typography
      variant="h2"
      sx={{
        fontSize: '24px',
        fontWeight: '700',
        lineHeight: '32px',
      }}
    >
      {children}
    </Typography>
  );
};

export const Section = styled('section')`
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 24px;
`;

export const PinkInfoBox = styled(Box)`
  padding: 12px;
  border-radius: 8px;
  background: #ffc5cc33;
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
