import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Contract } from './types';
import { TotalsRow } from './TotalsRow';
import { moneyPrint } from '@pv/common/utils';
import { formatPercentageRate } from './utils';

interface LineItemSummaryProps {
  contract: Contract;
}

export const LineItemSummary = ({ contract }: LineItemSummaryProps) => {
  if (!contract.totals) return null;
  return (
    <Stack spacing={1}>
      <TotalsRow>
        <Typography key="subtotal" variant="body1">
          Subtotal
        </Typography>
        <Typography key="subtotal-amount" variant="body1">
          {moneyPrint(contract.totals['sub_total'] / 100)}
        </Typography>
      </TotalsRow>

      {contract.totals['discount'] > 0 && (
        <TotalsRow>
          <Typography key="discount" variant="body1">
            Discount
          </Typography>
          <Typography key="discount-amount" variant="body1">
            {moneyPrint(contract.totals['discount'] / 100)}
          </Typography>
        </TotalsRow>
      )}
      {Object.entries(contract.totals.taxes).map(([taxName, taxAmount]) => {
        const tax = contract.contractTaxes.find(
          (contractTax) => contractTax.name === taxName,
        );

        if (!tax?.enabled) return null;

        const taxRate = tax ? tax.rate : 0;

        return (
          <TotalsRow key={taxName}>
            <Typography key={`${taxName}-name`} variant="body1">
              {taxName} {`(${formatPercentageRate(taxRate)})`}
            </Typography>
            <Typography key={`${taxName}-amount`} variant="body1">
              {moneyPrint(taxAmount / 100)}
            </Typography>
          </TotalsRow>
        );
      })}

      {Object.entries(contract.totals.fees).map(([feeName, feeAmount]) => {
        const fee = contract.contractFees.find(
          (contractFee) => contractFee.name === feeName,
        );

        if (!fee?.enabled) return null;

        const feeRate = fee ? fee.rate : 0;
        return (
          <TotalsRow key={feeName}>
            <Typography key={`${feeName}-name`} variant="body1">
              {feeName} {`(${formatPercentageRate(feeRate)})`}
            </Typography>
            <Typography key={`${feeName}-amount`} variant="body1">
              {moneyPrint(feeAmount / 100)}
            </Typography>
          </TotalsRow>
        );
      })}
      {contract.minimumEnabled && contract.totals['minimum_remaining'] && (
        <TotalsRow>
          <Typography
            key="minimum"
            variant="body1"
          >{`Minimum (${moneyPrint(contract.minimumAmount / 100)})`}</Typography>
          <Typography key="minimum-amount" variant="body1">
            {moneyPrint(contract.totals['minimum_remaining'] / 100)}
          </Typography>
        </TotalsRow>
      )}
    </Stack>
  );
};
