import React, { FC } from 'react';
import {
  Button,
  MenuItem,
  Select,
  Typography,
  FormControl,
  InputLabel,
  Stack,
} from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { offsetToTimeString } from '@pv/common/utils';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import { lowerCase, find, max, range } from 'lodash';

import { PVBlack, PVNavy } from '@pv/common/colors';

import type { EventTimeSelectorProps } from '../selectors/EventTimeSelector';
import type { VoidFn, Nullable, Procedure } from '../../../types/common';

/*
 * Constants.
 */

const defaultStartTimeOffset = 60;
const defaultEndTimeOffset = 1480;
const defaultStep = 30;

/*
 * Types.
 */

interface EventTimePickerProps extends EventTimeSelectorProps {
  onClose: Procedure;
  shouldRenderActionBar: boolean;
}

interface TimeSelectorProps {
  label: string;
  timeOffset: Nullable<number>;
  onChangeTimeOffset: VoidFn<SelectChangeEvent<number>>;
  startOffset?: number;
  endOffset?: number;
}

interface ControlledTimeSelectorProps {
  label: string;
  timeOffset: Nullable<number>;
  onChangeTimeOffset: VoidFn<SelectChangeEvent<number>>;
  children: React.ReactNode;
  disabled?: boolean;
  open?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  showIcon?: boolean;
  placeholder?: string;
  id: string;
}

/*
 * Components.
 */

export const EventTimePicker: FC<EventTimePickerProps> = ({
  eventDate,
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  clearStartTime,
  clearEndTime,
  onClose,
  shouldRenderActionBar,
  expressBookSettings,
  hoursOfOperation,
}: EventTimePickerProps) => {
  const minDurationTime =
    (expressBookSettings?.minEventDurationHours ?? 0) * 60;

  const endTimeStartsAfter = startTime
    ? startTime + minDurationTime
    : undefined;

  const eventDayOfWeek = lowerCase(eventDate?.format('dddd'));

  const hoursOfOperationForDay = find(hoursOfOperation, {
    dayOfWeek: eventDayOfWeek,
  });

  const firstAvailableEndTimeSlot = max([
    hoursOfOperationForDay?.startTime,
    endTimeStartsAfter,
  ]);

  const lastAvailableStartTimeSlot =
    hoursOfOperationForDay?.endTime ?? defaultEndTimeOffset;

  return (
    <Stack direction="column" gap="16px" width="100%">
      <Typography variant="h6" sx={{ height: '30px' }}>
        Select Time
      </Typography>
      <TimeSelector
        label="Start time"
        timeOffset={startTime}
        onChangeTimeOffset={onChangeStartTime}
        startOffset={hoursOfOperationForDay?.startTime}
        endOffset={lastAvailableStartTimeSlot - minDurationTime}
      />
      <TimeSelector
        label="End time"
        timeOffset={endTime}
        onChangeTimeOffset={onChangeEndTime}
        startOffset={firstAvailableEndTimeSlot}
        endOffset={hoursOfOperationForDay?.endTime}
      />
      {shouldRenderActionBar && (
        <div>
          <Button
            sx={{
              width: '90px',
              textTransform: 'none',
              color: PVNavy,
              borderRadius: '6px',
              border: '1px solid rgba(59, 57, 108, 0.15)',
              display: 'flex',
              justifyContent: 'center',

              '&:hover': {
                background: 'none',
              },
            }}
            onClick={() => {
              clearStartTime();
              clearEndTime();
              onClose();
            }}
          >
            Any time
          </Button>
        </div>
      )}
    </Stack>
  );
};

export const TimeSelector: FC<TimeSelectorProps> = ({
  label,
  timeOffset,
  onChangeTimeOffset,
  startOffset,
  endOffset,
}) => (
  <FormControl fullWidth>
    <InputLabel
      htmlFor={`time-selector-select-${label}`}
      id={`time-selector-${label}`}
    >
      {label}
    </InputLabel>
    <Select
      sx={{
        color: PVBlack,
        paddingLeft: '8px',
      }}
      labelId={`time-selector-${label}`}
      id={`time-selector-select-${label}`}
      label={label}
      value={timeOffset ?? ''}
      onChange={onChangeTimeOffset}
    >
      {generateTimeSelection(startOffset, endOffset)}
    </Select>
  </FormControl>
);

export const ControlledTimeSelector: FC<ControlledTimeSelectorProps> = ({
  label,
  timeOffset,
  onChangeTimeOffset,
  children,
  disabled = false,
  open = false,
  onOpen = () => {},
  onClose = () => {},
  showIcon = false,
  placeholder,
  id,
}) => {
  return (
    <FormControl fullWidth>
      <InputLabel htmlFor={id} id={`time-selector-${id}`}>
        {label}
      </InputLabel>
      <Select
        sx={{
          color: PVBlack,
          paddingLeft: '8px',
        }}
        labelId={`time-selector-${id}`}
        aria-labelledby={`time-selector-${id}`}
        id={id}
        label={label}
        value={timeOffset ?? ''}
        onChange={onChangeTimeOffset}
        disabled={disabled}
        open={open}
        onOpen={onOpen}
        onClose={onClose}
        startAdornment={
          showIcon && (
            <Stack direction={'row'}>
              <AccessTimeOutlinedIcon
                sx={{ color: PVBlack, marginRight: '8px' }}
              />{' '}
              {!timeOffset && (
                <Typography sx={{ color: 'rgba(0, 0, 0, 0.35)' }}>
                  {placeholder}
                </Typography>
              )}
            </Stack>
          )
        }
      >
        {children}
      </Select>
    </FormControl>
  );
};

/*
 * Helpers.
 */

const generateTimeSelection = (
  start: number = 60,
  end: number = 1480,
  step: number = 30,
) => {
  const timeOffsets = range(start, end + step, step);
  return timeOffsets.map((timeOffset: number) => {
    const timeString = offsetToTimeString(timeOffset);
    return (
      <MenuItem key={timeString} value={timeOffset}>
        {timeString}
      </MenuItem>
    );
  });
};
