import React, { useMemo } from 'react';
import { Stack, Typography } from '@mui/material';
import { Contract } from '../types';
import { moneyPrint } from '@pv/common/utils';
import { formatPercentageRate } from '../utils';
interface CartEmptyStateProps {
  contract: Contract;
}
export const CartEmptyState = ({ contract }: CartEmptyStateProps) => {
  const minimumAndDepositInfoText = useMemo(() => {
    if (
      !contract?.minimumEnabled &&
      !contract?.depositAmount &&
      !contract?.depositRate
    ) {
      return '';
    }

    // There is a $500 minimum and a 50% deposit to book this event.
    // There is a $500 minimum to book this event.
    // There is a 50% deposit to book this event.
    // There is a $500 deposit to book this event.
    let text = 'There is ';
    if (contract?.minimumEnabled && contract?.minimumAmount) {
      text += `a ${moneyPrint(contract.minimumAmount / 100)} minimum`;
    }
    if (
      contract?.minimumEnabled &&
      (contract?.depositAmount || contract?.depositRate)
    ) {
      text += ' and ';
    }

    if (contract?.depositAmount) {
      text += `a ${moneyPrint(contract.depositAmount / 100)} deposit`;
    } else if (contract?.depositRate) {
      text += `a ${formatPercentageRate(contract.depositRate)} Deposit`;
    }

    text += ' to book this event.';
    return text;
  }, [contract]);

  return (
    <Stack
      direction="column"
      alignItems="center"
      gap="10px"
      sx={{
        width: '100%',
        padding: '24px',

        borderRadius: '8px',
        background: '#FF8B991A',
      }}
    >
      <img
        src="/champagne-gray.svg"
        alt=""
        style={{
          width: '48px',
          height: '48px',
        }}
      />
      <Typography variant="body1" sx={{ textAlign: 'center' }}>
        <strong style={{ fontWeight: 500, textAlign: 'center' }}>
          {minimumAndDepositInfoText}
        </strong>
      </Typography>
      <Typography variant="body1">There are no items in your cart.</Typography>
      <Typography variant="body1">Add items to continue.</Typography>
    </Stack>
  );
};
