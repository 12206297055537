import { useCallback, useMemo } from 'react';
import dayjs, { Dayjs } from 'dayjs';

import type { HoursOfOperation } from '../types/models/venue_types';

/*
 * Helpers.
 */

export const useShouldDisableDate = (
  hoursOfOperation?: HoursOfOperation,
  minLeadTimeDays?: number,
  maxLeadTimeDays?: number
) => {
  const currentDate = useMemo(() => dayjs(), []);

  const closedDays = useMemo(
    () => hoursOfOperation?.filter((d) => !d.isOpen).map((d) => d.dayOfWeek),
    [hoursOfOperation]
  );

  return useCallback(
    (date: Dayjs) => {
      const dayOfWeek = date.format('dddd').toLowerCase();
      const daysDiff = date.diff(currentDate, 'd');

      if (!minLeadTimeDays || !maxLeadTimeDays || !closedDays) {
        return false;
      }

      if (closedDays.includes(dayOfWeek)) {
        return true;
      }

      return daysDiff < minLeadTimeDays || daysDiff > maxLeadTimeDays;
    },
    [closedDays, currentDate, minLeadTimeDays, maxLeadTimeDays]
  );
};
