import React, { FC } from 'react';
import { Box, Stack, Typography } from '@mui/material';

/*
 * Components.
 */

export const NotFoundPage: FC = () => (
  <Stack
    direction="column"
    alignItems="center"
    justifyContent="center"
    height="84vh"
    gap="40px"
  >
    <Box
      component="img"
      alt="Page not found"
      src="/Oven.png"
      style={{
        width: '14vw',
      }}
    />
    <Stack direction="column">
      <Typography
        variant="h3"
        sx={{
          fontSize: {
            xs: '26px',
            sm: '34px',
            md: '35px',
            lg: '39px',
            xl: '46px',
          },
          width: {
            xs: '374px',
            sm: '402px',
            md: '486px',
            lg: '526px',
            xl: '640px',
          },
          fontFamily: 'Gazpacho',
          fontWeight: 'bold',
          lineHeight: '120%',
          textAlign: 'center',
          marginBottom: '0px',
        }}
      >
        Whoops, looks like this page is half baked!
      </Typography>
      <Box
        component="img"
        alt="underline"
        src="/underline.svg"
        sx={{
          position: 'relative',
          left: {
            sm: '248px',
            md: '292px',
            lg: '318px',
            xl: '384px',
          },
          width: {
            sm: '112px',
            md: '116px',
            lg: '130px',
            xl: '153px',
          },
        }}
      />
    </Stack>
  </Stack>
);
