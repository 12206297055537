import React, { FC, useState, useMemo } from 'react';
import { Dayjs } from 'dayjs';
import { styled as muiStyled } from '@mui/material/styles';
import { Box, TextField, Popover, Stack } from '@mui/material';
import { Clock } from 'react-feather';
import type { SelectChangeEvent } from '@mui/material';

import { offsetToTimeString } from '@pv/common/utils';
import { PVBlack } from '@pv/common/colors';

import type { VoidFn, Nullable, Procedure } from '../../../types/common';
import { EventTimePicker } from '../pickers/EventTimePicker';
import { HoverOverlayDiv } from './HoverOverlay';
import type {
  PartialExpressBookSettings,
  HoursOfOperation,
} from '../../../types/models/venue_types';

/*
 * Types.
 */

export interface EventTimeSelectorProps {
  eventDate: Nullable<Dayjs>;
  startTime: Nullable<number>;
  endTime: Nullable<number>;
  onChangeStartTime: VoidFn<SelectChangeEvent<number>>;
  onChangeEndTime: VoidFn<SelectChangeEvent<number>>;
  clearStartTime: Procedure;
  clearEndTime: Procedure;
  expressBookSettings?: PartialExpressBookSettings;
  hoursOfOperation?: HoursOfOperation;
}

/*
 * Constants.
 */

const StyledEventTimeTextField = muiStyled(TextField)({
  input: {
    cursor: 'pointer',
  },
  'input::selection': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '&:hover + .HoverOverlay': {
    opacity: '0.2',
  },
  '&:focus-within + .HoverOverlay': {
    opacity: '0.2',
  },
});

/*
 * Components.
 */

export const EventTimeSelector: FC<EventTimeSelectorProps> = ({
  eventDate,
  startTime,
  endTime,
  onChangeStartTime,
  onChangeEndTime,
  clearStartTime,
  clearEndTime,
  expressBookSettings,
  hoursOfOperation,
}: EventTimeSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayEventTime = useMemo(() => {
    if (startTime === null || endTime === null) {
      return 'Any time';
    }

    return `${offsetToTimeString(startTime, false)} - ${offsetToTimeString(
      endTime,
      false,
    )}`;
  }, [startTime, endTime]);

  return (
    <Stack
      alignItems="center"
      direction="row"
      width="100%"
      position="relative"
      sx={{
        marginRight: '10px',
      }}
    >
      <Clock
        color={PVBlack}
        style={{
          marginBottom: '16px',
          marginLeft: '8px',
        }}
      />
      <StyledEventTimeTextField
        size="small"
        label="Time"
        value={displayEventTime}
        InputProps={{
          readOnly: true,
        }}
        onClick={handleClick}
        onKeyPress={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleClick(event);
          }
        }}
      />
      <HoverOverlayDiv className="HoverOverlay" />
      <Popover
        id="event-time-selector-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding="24px" width="368px">
          <EventTimePicker
            eventDate={eventDate}
            startTime={startTime}
            endTime={endTime}
            onChangeStartTime={onChangeStartTime}
            onChangeEndTime={onChangeEndTime}
            clearStartTime={clearStartTime}
            clearEndTime={clearEndTime}
            onClose={handleClose}
            shouldRenderActionBar
            expressBookSettings={expressBookSettings}
            hoursOfOperation={hoursOfOperation}
          />
        </Box>
      </Popover>
    </Stack>
  );
};
