import React, { forwardRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  AppBar,
  Divider,
  Link,
  Stack,
  Toolbar,
  Typography,
} from '@mui/material';
import { MarketplaceHomePageLink } from '../../components/MarketplaceHomePageLink';

import { useQuery } from '@apollo/client';

import { PVBlack } from '@pv/common/colors';

import { VENUE_NAV_BAR_QUERY } from './graphql';

export const NavigationBar = forwardRef<
  HTMLElement,
  { children?: React.ReactNode | React.ReactNode[] }
>(({ children }, ref) => {
  const params = useParams();
  const { venueSlug } = params;

  const { data } = useQuery(VENUE_NAV_BAR_QUERY, {
    variables: { venueSlug },
    skip: !venueSlug,
  });

  const navBarVenueLocationString = [
    data?.marketplaceVenue?.address?.city,
    data?.marketplaceVenue?.address?.state,
  ].join(', ');

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: '#fff',
        boxShadow: '0px 1px 0px 0px #0000001A',
      }}
      ref={ref}
    >
      <Toolbar>
        <Stack
          direction="row"
          alignItems="center"
          gap="16px"
          justifyContent="space-between"
          sx={{
            width: '100%',
          }}
        >
          <Stack direction="row" alignItems="center" gap="16px">
            <MarketplaceHomePageLink />
            <Divider orientation="vertical" variant="middle" flexItem />
            {/* hide venue name and location if small screen */}
            <Stack
              direction="row"
              alignItems="baseline"
              display={{ xs: 'none', sm: 'flex' }}
              gap="8px"
            >
              <Link
                variant="h6"
                sx={{
                  fontWeight: '700',
                  fontSize: '18px',
                  color: PVBlack,
                }}
                underline="hover"
                href={`/venues/${venueSlug}`}
              >
                {data?.marketplaceVenue?.name}
              </Link>
              <Typography
                variant="subtitle1"
                sx={{
                  fontWeight: '400',
                  fontSize: '16px',
                }}
              >
                {navBarVenueLocationString}
              </Typography>
            </Stack>
          </Stack>
          <Stack
            direction="row"
            alignItems="center"
            gap="16px"
            sx={{ color: 'black' }}
          >
            {children}
          </Stack>
        </Stack>
      </Toolbar>
    </AppBar>
  );
});
