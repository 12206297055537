import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import { EventStyleSelector } from '../selectors/EventStyleSelector';
import { GuestRangeSelector } from '../selectors/GuestRangeSelector';
import { LocationSelector } from '../selectors/LocationSelector';
import type { FilterBarProps } from '.';

/*
 * Components.
 */

export const MobileFilterBar: FC<FilterBarProps> = ({
  locationQuery,
  onChangeLocationQuery,
  eventStyle,
  onChangeEventStyle,
  guestRange,
  onChangeGuestRange,
}: FilterBarProps) => (
  <Box
    role="search"
    sx={{
      width: '100%',
    }}
  >
    <Stack
      sx={{
        borderRadius: '8px',

        border: '1px solid #E6E6E6',
        background: '#FFF',

        boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.10)',

        display: 'flex',
        flexDirection: 'column',
        padding: '30px 20px 10px 10px',
        gap: '10px',
      }}
    >
      <LocationSelector
        locationQuery={locationQuery}
        onChangeLocationQuery={onChangeLocationQuery}
      />
      <Separator />
      <EventStyleSelector
        eventStyle={eventStyle}
        onChangeEventStyle={onChangeEventStyle}
      />
      <Separator />
      <GuestRangeSelector
        guestRange={guestRange}
        onChangeGuestRange={onChangeGuestRange}
      />
    </Stack>
  </Box>
);

const Separator: FC = () => (
  <Divider orientation="horizontal" flexItem sx={{ marginBottom: '15px' }} />
);
