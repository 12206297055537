import React from 'react';

export const ContractItemImage = ({ imageUrl }: { imageUrl: string }) => {
  return (
    <img
      src={imageUrl}
      alt="item"
      style={{
        width: '60px',
        height: '60px',
        borderRadius: '6px',
        objectFit: 'cover',
      }}
    />
  );
};
