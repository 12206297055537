import { Button, CircularProgress, Typography } from '@mui/material';
import React from 'react';

export const SendRequestButton = ({
  loading = false,
}: {
  loading?: boolean;
}) => {
  return (
    <Button
      size="large"
      type="submit"
      variant="contained"
      color="secondary"
      sx={{
        height: '56px',
        maxWidth: {
          sm: '300px',
          xs: '100%',
        },
      }}
    >
      <Typography
        sx={{
          fontWeight: 700,
          fontSize: '20px',
          lineHeight: '26px',
          color: 'white',
        }}
      >
        {loading && <CircularProgress size={20} />}
        {loading ? 'Sending Request...' : 'Send Request'}
      </Typography>
    </Button>
  );
};
