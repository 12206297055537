import React from 'react';
import { MenuComponents as Menu } from '@pv/common/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import { moneyPrint } from '@pv/common/utils';
import { ChoiceList } from './ChoiceList';

const getChoiceLabel = (choice: Menu.Types.Choice) => {
  const { name, limit } = choice;
  return limit && limit > 0 ? `Choose ${limit} ${name}` : `Choose ${name}`;
};
interface PreviewMenuItemModalProps {
  open: boolean;
  menuItem: Menu.Types.MenuItem;
  onClose: () => void;
}

export const PreviewMenuItemModal = ({
  open,
  menuItem,
  onClose,
}: PreviewMenuItemModalProps) => {
  const theme = useTheme();
  const expandToFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={'md'}
      fullScreen={expandToFullScreen}
    >
      <DialogTitle sx={{ flexShrink: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '32px',
            }}
          >
            {menuItem.name}
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{ overflow: 'auto', flexGrow: 1, padding: 0, width: '645px' }}
      >
        <Stack
          direction="column"
          gap="20px"
          sx={{
            overflowY: 'auto',
            padding: '0px 20px 20px 20px',
            maxWidth: '100%',
            flexGrow: 1,
          }}
        >
          {menuItem.image && (
            <img
              src={menuItem.image}
              alt={menuItem.name}
              style={{
                width: '100%',
                aspectRatio: '8 / 5',
                objectFit: 'cover',
                borderRadius: '6px',
              }}
            />
          )}
          <Stack gap="5px">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              {menuItem.price && moneyPrint(menuItem.price / 100)}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '14px',
              }}
            >
              {menuItem.unit && `Per ${menuItem.unit}`}
            </Typography>
          </Stack>

          <Typography variant="body1">{menuItem.details}</Typography>

          {menuItem.choices?.map((choice) => (
            <ChoiceList
              key={choice.id}
              label={getChoiceLabel(choice)}
              choice={choice}
            />
          ))}
          {menuItem.addons?.length > 0 && (
            <ChoiceList
              choice={{
                id: 'addons',
                name: 'Add-ons',
                options: menuItem.addons,
              }}
              label="Add-ons"
            />
          )}
        </Stack>
      </DialogContent>
    </Dialog>
  );
};
