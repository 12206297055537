import React from 'react';

import { PartialVenue } from '../../../types/models/venue_types';
import { Space } from '../../../types/models/space_types';
import { buildMappableAddressCoord } from '../../../helpers/api/addresses';

import { useFilteredSearchResults } from './FilteredSearchResultsProvider';
import { useNavigate } from 'react-router-dom';

export const MappableVenuesContext = React.createContext<{
  mappableVenues: Array<{
    key: string;
    name: string;
    position: { lat: number; lng: number };
    spaces: Space[];
    handleClick: () => void;
  }>;
}>({
  mappableVenues: [],
});

export const MappableVenuesProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { filteredVenues, filteredSpaces } = useFilteredSearchResults();

  const navigate = useNavigate();
  const mappableVenues = React.useMemo(() => {
    return filteredVenues
      .filter((venue: PartialVenue) => !!venue)
      .map((venue: PartialVenue) => ({
        key: venue.slug || '',
        name: venue.name || '',
        position: buildMappableAddressCoord(venue.address),
        spaces: filteredSpaces?.filter(
          (space: Space) => space.venue.slug === venue.slug,
        ),
        handleClick: () => window.open(`/venues/${venue.slug}`),
      }));
  }, [filteredSpaces, filteredVenues, navigate]);

  return (
    <MappableVenuesContext.Provider value={{ mappableVenues }}>
      {children}
    </MappableVenuesContext.Provider>
  );
};

export const useMappableVenues = () => React.useContext(MappableVenuesContext);
