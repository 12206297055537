import React, { useMemo } from 'react';

import { Contract } from '../types';

import { ShoppingCart } from 'react-feather';
import { PVRed } from '@pv/common/colors';

import { Typography, Button, Stack } from '@mui/material';
import { moneyPrint } from '@pv/common/utils';

interface CartButtonProps {
  onClick: () => void;
  contract: Contract;
}
export const CartButton = ({ onClick, contract }: CartButtonProps) => {
  const itemCountText = useMemo(() => {
    if (!contract?.contractItems) {
      return 'View Cart';
    }
    return `${contract.contractItems.length} items`;
  }, [contract]);

  const subtotalText = useMemo(() => {
    if (!contract?.contractItems || !contract?.contractItems.length) {
      return null;
    }
    const itemSubtotal = contract.contractItems.reduce((acc, item) => {
      return acc + (item.total || 0);
    }, 0);
    return moneyPrint((itemSubtotal || 0) / 100);
  }, [contract]);

  const minimumText = useMemo(() => {
    if (
      !contract?.minimumEnabled ||
      !contract?.minimumAmount ||
      !contract?.totals ||
      !contract?.totals['minimum_remaining']
    ) {
      return '';
    }
    return `(${moneyPrint(contract.minimumAmount / 100)} Minimum)`;
  }, [contract]);

  if (!contract?.contractItems) {
    return null;
  }

  return (
    <Button title="View Cart" onClick={onClick}>
      <Stack direction="row" alignItems="center" gap="16px">
        <ShoppingCart color={PVRed} />
        <Typography variant="body2">{itemCountText}</Typography>
        {/* hide total if small screen */}

        {subtotalText && (
          <Typography
            variant="subtitle1"
            sx={{
              display: { xs: 'none', sm: 'block' },
            }}
          >
            {subtotalText}
          </Typography>
        )}
        <Typography
          variant="subtitle1"
          sx={{
            display: { xs: 'none', sm: 'block' },
          }}
        >
          {minimumText}
        </Typography>
      </Stack>
    </Button>
  );
};
