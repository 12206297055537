import type { PartialAddress } from '../../types/models/address_types';

/*
 * Constants.
 */

// The Perfect Venue office
export const defaultCenter = {
  lat: 37.80526,
  lng: -122.40476,
};

/*
 * Helpers.
 */

export const buildMappableAddressCoord = (address?: PartialAddress) => {
  if (!address || !address.latitude || !address.longitude) {
    return defaultCenter;
  }

  return { lat: address.latitude, lng: address.longitude };
};
