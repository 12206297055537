import { styled } from '@mui/system';
import { Box, Step, Grid } from '@mui/material';
import { PVRed, PVMediumGray } from '@pv/common/colors';

export const InfoContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
`;

export const RoundedImageContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 418px;
    height: 200px;
  }
  ${({ theme }) => theme.breakpoints.down('sm')} {
    width: 123px;
    height: 94px;
  }

  border-radius: 16px;
`;

export const SpaceDetailsContainer = styled(Box)`
  display: flex;
  gap: 16px;
  ${({ theme }) => theme.breakpoints.down('sm')} {
    flex-direction: row;
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    flex-direction: column;
  }
`;

export const EventInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SpaceAndEventInfoContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid #0000001a;
  border-radius: 16px;
  box-shadow: 0px 2px 12px 0px #00000014;
  width: 100%;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    max-width: 466px;
    align-self: flex-end;
    padding: 24px;
  }
`;

export const SpaceImage = styled('img')`
  border-radius: 6px;
  object-fit: cover;
  height: 100%;
  width: 100%;
`;

export const EventRequestHeaderContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 24px;
  gap: 32px;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  align-items: center;
`;

export const StyledStep = styled(Step)(() => ({
  '& .MuiStepIcon-root': {
    borderColor: PVMediumGray,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: '64px',
    fill: 'white',
    '& .MuiSvgIcon-root': {
      fill: 'white',
    },
    '& .MuiStepIcon-text': {
      fill: PVMediumGray,
    },

    '&.Mui-active': {
      borderColor: PVRed,
      fill: 'white',
      '& .MuiSvgIcon-root': {
        fill: PVRed,
      },
      '& .MuiStepIcon-text': {
        fill: PVRed,
      },
    },
    '&.Mui-completed': {
      borderColor: PVRed,
      fill: PVRed,
      '& .MuiSvgIcon-root': {
        fill: PVRed,
      },
      '& .MuiStepIcon-text': {
        fill: 'white',
      },
    },
  },
}));
export const TwoColumnLayout = styled(Grid)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
`;
export const ColumnContainer = styled(Grid)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
`;

export const PageContainer = styled(Box)`
  max-width: 1200px;
  margin: auto;
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px 16px;
  position: relative;
  min-height: calc(100vh - 64px);
  margin-top: 64px;

  @media (min-width: 600px) {
    padding: 24px;
  }
`;

export const PinkOmbreContainer = styled(Box)`
  border-radius: 20px;
  padding: 16px;
  gap: 8px;
  display: flex;
  flex-direction: column;
  align-self: flex-end;
  background: linear-gradient(93.83deg, #ffede7 4.44%, #ffdef0 95.56%);
    ${({ theme }) => theme.breakpoints.up('sm')} {
    padding: 24px;
    max-width: 466px;
  },
`;

export const EventInfoFormContainer = styled(Box)`
  ${({ theme }) => theme.breakpoints.down('sm')} {
      width: '100%',
      padding: '24px',
    },
  ${({ theme }) => theme.breakpoints.up('sm')} {
    maxWidth: '564px',
  },
`;
