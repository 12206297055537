import React, { FC, ReactNode } from 'react';
import { Box, Stack, Toolbar, Typography, Link } from '@mui/material';

import { PVGray, PVWhite } from '@pv/common/colors';

/*
 * Types.
 */

interface FooterBarProps {}

interface FooterLinkProps {
  href: string;
  title?: string;
  children: ReactNode;
}

/*
 * Constants.
 */

const FooterLink: FC<FooterLinkProps> = ({ href, children }) => (
  <Link
    href={href}
    target="_blank"
    rel="noopener,noreferrer"
    variant="body2"
    underline="hover"
    color={PVGray}
  >
    {children}
  </Link>
);

export const FooterBar: FC<FooterBarProps> = () => (
  <Box
    position="static"
    sx={{
      backgroundColor: PVWhite,
      boxShadow: 'none',
    }}
  >
    <Toolbar disableGutters>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        gap="32px"
        margin={{ xs: '16px 0px', sm: '16px 0px', md: '0px' }}
        sx={{ width: '100%', justifyContent: 'space-between' }}
      >
        <Stack
          direction={{ xs: 'column', sm: 'row' }}
          gap={{ xs: '10px', sm: '10px', md: '32px' }}
        >
          <Typography variant="body2" color={PVGray}>
            &copy; 2024 Perfect Venue
          </Typography>
          <FooterLink href="https://www.perfectvenue.com/terms-of-service">
            Terms of Service
          </FooterLink>
          <FooterLink href="https://www.perfectvenue.com/privacy-policy">
            Privacy Policy
          </FooterLink>
        </Stack>
      </Stack>
    </Toolbar>
  </Box>
);
