import React from 'react';

import { Stack, useMediaQuery, useTheme, Typography } from '@mui/material';

import { defaultCenter } from '../../../helpers/api/addresses';

import { LoadingIndicator } from '@pv/common/components';
import { PVBlack } from '@pv/common/colors';

import { GoogleMap } from '../../../components/GoogleMap';
import { LocationSearchFilterBar as FilterBar } from '../../../components/FilterBar';
import { SpacesShelf } from '../../../components/SpacesShelf';
import { FooterBar } from '../../../components/FooterBar';
import {
  parseEventStyle,
  parseGuestRange,
} from '../../../types/components/filter_bar_types';

import { useSearchResults } from '../providers/SearchResultsProvider';
import { useFilteredSearchResults } from '../providers/FilteredSearchResultsProvider';
import { useMappableVenues } from '../providers/MappableVenuesProvider';

export const SearchResults = () => {
  const theme = useTheme();
  const lgMedia = useMediaQuery(theme.breakpoints.up('lg'));
  const smMedia = useMediaQuery(theme.breakpoints.down('md'));

  const buildMapWidth = () => {
    if (lgMedia) {
      return '33vw';
    }

    return '50vw';
  };

  const mapStyle = {
    width: buildMapWidth(),
    height: '78vh',
  };

  const {
    locationQuery,
    setLocationQuery,
    loading: spacesLoading,
    center,
  } = useSearchResults();
  const {
    filteredSpaces,
    eventStyle,
    setEventStyle,
    guestRange,
    setGuestRange,
  } = useFilteredSearchResults();

  const { mappableVenues } = useMappableVenues();

  return (
    <>
      <Stack
        direction="column"
        alignItems="center"
        gap="6px"
        sx={{
          padding: '0px 24px 12px 24px',
          width: '100%',
          boxShadow: '0px -1px 0px 0px rgba(0, 0, 0, 0.1) inset',
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: 'center',
            color: PVBlack,
            fontFamily: 'Gazpacho',
            fontSize: {
              xs: '36px',
              md: '44px',
            },
            fontWeight: '900',
            lineHeight: '48px',
          }}
        >
          Find your perfect venue
        </Typography>
        <FilterBar
          locationQuery={locationQuery}
          onChangeLocationQuery={(queryString: string) =>
            setLocationQuery(queryString || '')
          }
          eventStyle={eventStyle}
          onChangeEventStyle={(e) =>
            setEventStyle(parseEventStyle(e.target.value))
          }
          guestRange={guestRange}
          onChangeGuestRange={(e) =>
            setGuestRange(parseGuestRange(e.target.value))
          }
        />
      </Stack>
      <Stack direction="row">
        <Stack
          justifyContent="space-between"
          sx={{
            padding: '24px',
            maxHeight: '78vh',
            overflowY: 'scroll',
            width: {
              sm: '100vw',
              md: '50vw',
              lg: '67vw',
            },
          }}
        >
          {spacesLoading ? (
            <LoadingIndicator />
          ) : (
            <SpacesShelf
              spaces={filteredSpaces || []}
              previewWidth="364"
              gridTemplateColumns={{
                xs: '1fr',
                sm: '1fr',
                md: '1fr',
                lg: 'repeat(2, 1fr)',
                xl: 'repeat(3, 1fr)',
              }}
            />
          )}
          <FooterBar />
        </Stack>
        {!smMedia && mappableVenues && (
          <GoogleMap
            center={
              center
                ? {
                    lat: center.latitude || defaultCenter.lat,
                    lng: center.longitude || defaultCenter.lng,
                  }
                : defaultCenter
            }
            mapStyle={mapStyle}
            mappableItems={mappableVenues}
          />
        )}
      </Stack>
    </>
  );
};
