import React from 'react';
import { Stepper, StepLabel, Typography, Stack } from '@mui/material';
import { StyledStep } from './styledComponents';

interface Props {
  activeStepIndex: number;
}

const steps = [
  {
    stepLabel: 'Details',
    pageTitle: 'Event Details',
  },
  {
    stepLabel: 'Menu',
    pageTitle: 'Select Menu',
  },
  {
    stepLabel: 'Request',
    pageTitle: 'Request to Book',
  },
];

const getPageTitle = (activeStepIndex: number) => {
  if (activeStepIndex < steps.length) {
    return steps[activeStepIndex].pageTitle;
  }
  return 'Request Sent';
};

export const StepCounter = ({ activeStepIndex }: Props) => {
  return (
    <Stack
      alignItems="center"
      sx={{
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        gap: '32px',
        padding: {
          xs: '0 0  24px 0',
          sm: '24px',
        },
      }}
    >
      <Typography
        variant="h1"
        sx={{
          fontSize: '30px',
          fontWeight: '700',
          lineHeight: '34.5px',
        }}
      >
        {getPageTitle(activeStepIndex)}
      </Typography>
      <Stepper
        activeStep={activeStepIndex}
        alternativeLabel
        sx={{ width: '100%' }}
      >
        {steps.map((step) => (
          <StyledStep key={step.stepLabel}>
            <StepLabel>{step.stepLabel}</StepLabel>
          </StyledStep>
        ))}
      </Stepper>
    </Stack>
  );
};
