import React from 'react';

import { TextField, IconButton, InputLabel, Stack } from '@mui/material';
import { Plus, Minus } from 'react-feather';

interface UpDownButtonProps {
  title: string;
  onClick: () => void;
  disabled?: boolean;
  children: React.ReactNode;
}

const iconSize = 16;

const UpDownButton = ({
  title,
  onClick,
  disabled = false,
  children,
}: UpDownButtonProps) => (
  <IconButton
    title={title}
    onClick={onClick}
    disabled={disabled}
    sx={{
      border: '1px solid #D9D9D9',
      height: '24px',
      width: '24px',
      borderRadius: '48px',
      padding: '4px',
    }}
  >
    {children}
  </IconButton>
);
interface QuantityInputProps {
  label: string;
  quantity: number;
  setQuantity: (quantity: number) => void;
}
export const QuantityInput = ({
  label,
  quantity,
  setQuantity,
}: QuantityInputProps) => {
  return (
    <Stack direction="row" alignItems="center" gap={'8px'}>
      <InputLabel
        htmlFor="quantity-selector"
        sx={{
          fontSize: '14px',
          fontWeight: '500',
          opacity: 1,
          lineHeight: '24px',
        }}
      >
        {label}
      </InputLabel>
      <UpDownButton
        title="Decrease quantity"
        onClick={() => {
          if (quantity > 0) {
            setQuantity(quantity - 1);
          } else {
            setQuantity(1);
          }
        }}
        disabled={quantity === 1}
      >
        <Minus width={iconSize} height={iconSize} />
      </UpDownButton>

      <TextField
        id="quantity-selector"
        variant="outlined"
        value={quantity}
        size="small"
        sx={{
          width: '100px',
        }}
        onChange={(e) => {
          const qty = parseInt(e.target.value);
          if (!isNaN(qty) && qty > 0) {
            setQuantity(qty);
          } else {
            setQuantity(1);
          }
        }}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            e.currentTarget.blur();
          }
          if (e.key === 'Escape') {
            e.currentTarget.blur();
          }
          if (e.key === 'Tab') {
            e.currentTarget.blur();
          }
          if (e.key === 'ArrowUp') {
            setQuantity(quantity + 1);
          }
          if (e.key === 'ArrowDown') {
            setQuantity(quantity - 1);
          }
        }}
      />
      <UpDownButton
        title="Increase quantity"
        onClick={() => {
          setQuantity(quantity + 1);
        }}
      >
        <Plus height={iconSize} width={iconSize} />
      </UpDownButton>
    </Stack>
  );
};
