import React, { FC } from 'react';
import { startCase } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import type { EventStyleSelectorProps } from '../selectors/EventStyleSelector';
import { eventStyleEnum } from '../../../types/components/filter_bar_types';

/*
 * Types.
 */

interface EventStylePickerProps extends EventStyleSelectorProps {
  shouldShowOutlineSelector: boolean;
  shouldRenderIcon: boolean;
  styleProps?: { [key: string]: string };
}

/*
 * Constants.
 */
const removeOutlineStyle = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

/*
 * Components.
 */

export const EventStylePicker: FC<EventStylePickerProps> = ({
  shouldShowOutlineSelector,
  shouldRenderIcon,
  eventStyle,
  onChangeEventStyle,
  styleProps,
}) => {
  const style = {
    ...(styleProps ?? {}),
    ...(shouldShowOutlineSelector ? {} : removeOutlineStyle),
  };

  return (
    <FormControl fullWidth>
      <InputLabel id="event-style-select-label">Style</InputLabel>
      <Select
        sx={style}
        labelId="event-style-select-label"
        id="event-style-select"
        label="Style"
        value={eventStyle}
        onChange={onChangeEventStyle}
        IconComponent={shouldRenderIcon ? undefined : () => null}
        size="small"
      >
        {eventStyleEnum.options.map((eventStyle: string) => (
          <MenuItem key={eventStyle} value={eventStyle}>
            {startCase(eventStyle)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
