import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { Helmet } from 'react-helmet';

import { fetchVenue } from '../../helpers/api/graphql';

interface VenueAvailabilityDay {
  dayOfWeek: string;
  startTime: number;
  endTime: number;
  isOpen: boolean;
}
/*
 * Constants.
 */

const formatDayOfWeekForMetadata = (dayOfWeek: string) => {
  // transform "monday" to "Mo"
  const twoLetterDay = dayOfWeek.slice(0, 2);
  const capitalizedAbbreviatedDay =
    twoLetterDay.charAt(0).toUpperCase() + twoLetterDay.slice(1);
  return capitalizedAbbreviatedDay;
};

const formatTimeOffsetForMetadata = (timeOffset: number) => {
  // time is the number of minutes since midnight
  const hours = Math.floor(timeOffset / 60);
  const minutes = timeOffset % 60;
  const formattedHours = hours.toString().padStart(2, '0');
  const formattedMinutes = minutes.toString().padStart(2, '0');
  return `${formattedHours}:${formattedMinutes}`;
};

/*
 * Components.
 */

export const VenueSeoMarkup = ({ venueSlug }: { venueSlug: string }) => {
  const { data: venueData, loading: venueLoading } = useQuery(fetchVenue, {
    variables: {
      slug: venueSlug,
    },
    skip: !venueSlug,
  });

  const venueOpeningHoursFormattedForMetadata = useMemo(() => {
    const hoursOfOperation = venueData?.marketplaceVenue?.hoursOfOperation;
    return hoursOfOperation?.map((day: VenueAvailabilityDay) => {
      if (day.isOpen) {
        return `${formatDayOfWeekForMetadata(
          day.dayOfWeek
        )}: ${formatTimeOffsetForMetadata(
          day.startTime
        )} - ${formatTimeOffsetForMetadata(day.endTime)}`;
      }
      return null;
    });
  }, [venueData?.marketplaceVenue?.hoursOfOperation]);

  if (venueLoading) {
    return null;
  }
  const venue = venueData?.marketplaceVenue;

  if (!venue) {
    return null;
  }

  return (
    <Helmet>
      <title>{venue.name} - Perfect Venue Marketplace</title>
      <meta name="description" content={venue.description} />
      <meta property="og:image" content={venue.profileBanner} />
      <meta
        property="og:title"
        content={`${venue.name} - Perfect Venue Marketplace`}
      />
      <meta property="og:description" content={venue.description} />
      <meta property="og:url" content={window.location.href} />
      <meta property="og:type" content="place" />
      <meta property="og:latitude" content={venue.address?.latitude} />
      <meta property="og:longitude" content={venue.address?.longitude} />
      <meta
        property="og:street-address"
        content={venue.address?.streetAddress1}
      />
      <meta property="og:locality" content={venue.address?.city} />
      <meta property="og:region" content={venue.address?.state} />
      <meta property="og:postal-code" content={venue.address?.zip} />
      <meta property="og:country-name" content={venue.address?.country} />
      <meta property="og:site_name" content="Perfect Venue Marketplace" />
      <meta property="og:locale" content="en_US" />

      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'Restaurant',
          name: venue.name,
          description: venue.description,
          logo: venue.logo,
          address: {
            '@type': 'PostalAddress',
            streetAddress: venue.address?.streetAddress1,
            addressLocality: venue.address?.city,
            addressRegion: venue.address?.state,
            postalCode: venue.address?.zip,
            addressCountry: venue.address?.country,
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: venue.address?.latitude,
            longitude: venue.address?.longitude,
          },
          image: venue.profileBanner,
          url: venue.websiteUrl,
          acceptsReservations: 'true',
          menu: venue.menu.pdf,
          openingHours: venueOpeningHoursFormattedForMetadata,
        })}
      </script>
    </Helmet>
  );
};
