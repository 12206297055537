import React from 'react';

import { Divider } from '@mui/material';

import { EventSummary } from '../EventSummary';
import { OrderSummary } from './OrderSummary';

import { SpaceAndEventInfoContainer } from '../styledComponents';

import { Venue, Space, Event, Contract } from '../types';
interface CheckoutSummaryProps {
  venue: Venue;
  space: Space;
  event: Event;
  contract: Contract;
}
export const CheckoutSummary = ({
  venue,
  space,
  event,
  contract,
}: CheckoutSummaryProps) => {
  return (
    <SpaceAndEventInfoContainer>
      {venue && space && event && (
        <EventSummary
          venue={venue}
          space={space}
          startDate={event.startDate}
          startOffset={event.startOffset}
          endOffset={event.endOffset}
        />
      )}
      <Divider />
      {contract && <OrderSummary contract={contract} />}
    </SpaceAndEventInfoContainer>
  );
};
