import React, { FC } from 'react';
import { keys } from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import type { GuestRangeSelectorProps } from '../selectors/GuestRangeSelector';
import { displayNameToGuestRangeMap } from '../selectors/GuestRangeSelector';

/*
 * Types.
 */

interface GuestRangePickerProps extends GuestRangeSelectorProps {
  shouldShowOutlineSelector: boolean;
  shouldRenderIcon: boolean;
  styleProps?: { [key: string]: string };
}

/*
 * Constants.
 */

const removeOutlineStyle = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
};

/*
 * Components.
 */

export const GuestRangePicker: FC<GuestRangePickerProps> = ({
  guestRange,
  onChangeGuestRange,
  shouldShowOutlineSelector,
  shouldRenderIcon,
  styleProps,
}: GuestRangePickerProps) => {
  const style = {
    ...(styleProps ?? {}),
    ...(shouldShowOutlineSelector ? {} : removeOutlineStyle),
  };

  return (
    <FormControl fullWidth>
      <InputLabel htmlFor="guest-limit-select" id="guest-limit-select-label">
        Guests
      </InputLabel>
      <Select
        sx={style}
        labelId="guest-limit-select-label"
        id="guest-limit-select"
        label="Guests"
        value={guestRange}
        onChange={onChangeGuestRange}
        IconComponent={shouldRenderIcon ? undefined : () => null}
        size="small"
      >
        {keys(displayNameToGuestRangeMap).map((displayName: string) => (
          <MenuItem
            key={displayName}
            value={displayNameToGuestRangeMap[displayName]}
          >
            {displayName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
