import React from 'react';
import { X as CloseIcon } from 'react-feather';
import {
  Typography,
  Stack,
  Drawer,
  IconButton,
  Divider,
  Box,
  Button,
} from '@mui/material';
import { Contract, ContractItem as ContractItemType } from '../types';
import { ContractItem } from './ContractItem';
import { TotalsRow } from '../TotalsRow';
import { moneyPrint } from '@pv/common/utils';
import { CartEmptyState } from './CartEmptyState';
import { LineItemSummary } from '../LineItemSummary';
import { formatPercentageRate } from '../utils';

interface OrderSummaryProps {
  contract: Contract;
  open: boolean;
  onClose: () => void;
  onClickEdit: (contractItem: ContractItemType) => void;
  onClickRemove: (id: string) => void;
  onClickCheckout: () => void;
}

export const OrderSummary = ({
  contract,
  open,
  onClose,
  onClickEdit,
  onClickRemove,
  onClickCheckout,
}: OrderSummaryProps) => {
  if (!contract.totals) return null;
  const depositRateString = formatPercentageRate(contract.depositRate);
  const depositRowTitle = contract.depositRate
    ? `Deposit Due (${depositRateString})`
    : 'Deposit Due';
  return (
    <Drawer
      open={open}
      onClose={onClose}
      anchor="right"
      sx={{
        border: '1px solid #E6E6E6',
        boxShadow: '0px 2px 20px 0px #00000026',
        '& .MuiDrawer-paper': {
          top: '64px',
          bottom: '0',
          boxShadow: '0px 2px 20px 0px #00000026',
          border: '1px solid #E6E6E6',
          padding: '0 0 64px 0',
        },
        '& .MuiModal-backdrop': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <Box
        sx={{
          width: {
            xs: '100%',
            sm: '466px',
          },
          padding: {
            xs: `16px`,
            sm: '24px',
          },
        }}
      >
        {contract?.contractItems?.length === 0 && (
          <CartEmptyState contract={contract} />
        )}
        {contract && contract.contractItems.length > 0 && (
          <Stack spacing={2}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="h5">Order Summary</Typography>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </Stack>
            <Divider />
            <LineItemSummary contract={contract} />
            <Divider />
            <Stack spacing={1}>
              <TotalsRow>
                <Typography key="total" variant="h6">
                  Total
                </Typography>
                <Typography key="total-amount" variant="h6">
                  {moneyPrint(contract.totals['grand_total'] / 100)}
                </Typography>
              </TotalsRow>
              <TotalsRow>
                <Typography key="subtotal" variant="subtitle1">
                  {depositRowTitle}
                </Typography>
                <Typography key="subtotal-amount" variant="subtitle1">
                  {moneyPrint(contract.totals['deposit'] / 100)}
                </Typography>
              </TotalsRow>
            </Stack>

            <Button
              onClick={onClickCheckout}
              color="secondary"
              variant="contained"
              fullWidth
              size="large"
              sx={{
                marginTop: '16px',
              }}
            >
              <Typography
                sx={{
                  fontSize: '16px',
                  lineHeight: '26px',
                  fontWeight: '700',
                  color: 'white',
                }}
              >
                Checkout
              </Typography>
            </Button>
            <Divider />

            <Stack spacing={1}>
              {contract.contractItems?.map((contractItem: ContractItemType) => {
                if (contractItem.id) {
                  return (
                    <ContractItem
                      key={contractItem.id}
                      id={contractItem.id}
                      name={contractItem.name}
                      price={contractItem.price}
                      quantity={contractItem.quantity}
                      image={contractItem.image}
                      total={contractItem.total}
                      unit={contractItem.unit}
                      choices={contractItem.choices}
                      addons={contractItem.addons}
                      optionIds={contractItem.optionIds}
                      addonIds={contractItem.addonIds}
                      onClickRemove={onClickRemove}
                      onClickEdit={() => onClickEdit(contractItem)}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </Stack>
          </Stack>
        )}
      </Box>
    </Drawer>
  );
};
