import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import { EventDateSelector } from '../selectors/EventDateSelector';
import { EventTimeSelector } from '../selectors/EventTimeSelector';
import { EventStyleSelector } from '../selectors/EventStyleSelector';
import { GuestRangeSelector } from '../selectors/GuestRangeSelector';
import type { FilterBarProps } from './index';

/*
 * Components.
 */

export const WebFilterBar: FC<FilterBarProps> = ({
  eventDate,
  onChangeEventDate,
  startTime,
  onChangeStartTime,
  clearStartTime,
  endTime,
  onChangeEndTime,
  clearEndTime,
  eventStyle,
  onChangeEventStyle,
  guestRange,
  onChangeGuestRange,
  expressBookSettings,
  hoursOfOperation,
}: FilterBarProps) => (
  <Box
    role="search"
    height="70px"
    width="100%"
    sx={{
      padding: '16px 16px 0px',
      margin: '24px',

      borderRadius: '50px',
      border: '1px solid #E6E6E6',
      background: '#FFF',

      boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.10)',

      display: 'grid',
      gridTemplateColumns: '25% 25% 25% 25%',
      alignItems: 'center',
    }}
  >
    <Stack direction="row" justifyContent="space-between">
      <EventDateSelector
        eventDate={eventDate}
        onChangeEventDate={onChangeEventDate}
        expressBookSettings={expressBookSettings}
        hoursOfOperation={hoursOfOperation}
      />
      <Separator />
    </Stack>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ padding: '0px 10px' }}
    >
      <EventTimeSelector
        eventDate={eventDate}
        startTime={startTime}
        endTime={endTime}
        onChangeStartTime={onChangeStartTime}
        onChangeEndTime={onChangeEndTime}
        clearStartTime={clearStartTime}
        clearEndTime={clearEndTime}
        expressBookSettings={expressBookSettings}
        hoursOfOperation={hoursOfOperation}
      />
      <Separator />
    </Stack>
    <Stack direction="row" justifyContent="space-between">
      <EventStyleSelector
        eventStyle={eventStyle}
        onChangeEventStyle={onChangeEventStyle}
      />
      <Separator />
    </Stack>
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ padding: '0px 0px 0px 8px' }}
    >
      <GuestRangeSelector
        guestRange={guestRange}
        onChangeGuestRange={onChangeGuestRange}
      />
    </Stack>
  </Box>
);

const Separator: FC = () => (
  <Divider orientation="vertical" flexItem sx={{ marginBottom: '10px' }} />
);
