import React, { useState } from 'react';
import { Stack, Box, Typography, IconButton } from '@mui/material';
import { Trash } from 'react-feather';
import { moneyPrint } from '@pv/common/utils';

import { ContractItemImage } from './ContractItemImage';
import { Choice, Option } from '../types';
import { ContractItemChoices } from './ContractItemChoices';

interface ContractItemProps {
  id: string;
  name?: string | null;
  price?: number | null;
  quantity?: number | null;
  image?: string | null;
  unit: string;
  total?: number | null;
  choices?: Choice[];
  addons?: Option[];
  optionIds?: string[];
  addonIds?: string[];
  onClickEdit: () => void;
  onClickRemove: (id: string) => void;
}
export const ContractItem = ({
  id,
  name = '',
  price = undefined,
  quantity = undefined,
  image = undefined,
  unit,
  total = undefined,
  choices = [],
  addons = [],
  optionIds = [],
  addonIds = [],
  onClickEdit,
  onClickRemove,
}: ContractItemProps) => {
  const [active, setActive] = useState(false);
  const priceString = total ? moneyPrint(total / 100) : '';

  return (
    <Box
      component="div"
      aria-label={`Edit ${name}`}
      onClick={onClickEdit}
      onFocus={() => setActive(true)}
      onBlur={() => setActive(false)}
      onMouseEnter={() => setActive(true)}
      onMouseLeave={() => {
        setActive(false);
      }}
      onTouchStart={() => setActive(!active)}
      tabIndex={0}
      role="button"
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          onClickEdit();
        }
      }}
      sx={{
        cursor: 'pointer',
        textAlign: 'left',
        padding: '12px',
        borderRadius: '8px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'stretch',
        gap: '12px',
        boxShadow: '0px 2px 10px 0px #0000001a',
        border: '1px solid #0000001A',
        '&:hover, &.Mui-focusVisible, &:focus, &:active': {
          backgroundColor: '#fb59510a',
          boxShadow: '0px 2px 10px 0px #0000001a',
          borderColor: 'transparent',
        },
      }}
    >
      {image && <ContractItemImage imageUrl={image} />}
      <Stack direction="column" alignItems="stretch" sx={{ flexGrow: 1 }}>
        <Stack
          // the name, quantity, and total price of the item
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Typography
            variant="subtitle1"
            sx={{ flexGrow: 1, alignSelf: 'flex-start' }}
          >
            {name}
          </Typography>
          <Stack direction="row" justifyContent="space-between" spacing={2}>
            <Typography
              variant="subtitle2"
              color="text.secondary"
            >{`x${quantity}`}</Typography>
            <Typography
              variant="subtitle2"
              sx={{ minWidth: '80px', textAlign: 'right' }}
            >
              {priceString}
            </Typography>
          </Stack>
        </Stack>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="stretch"
          sx={{
            height: '100%',
          }}
        >
          <Box>
            <ContractItemChoices
              choices={choices}
              addons={addons}
              optionIds={optionIds}
              addonIds={addonIds}
            />
          </Box>
          <IconButton
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
            aria-label="Remove Item"
            color={'error'}
            size="small"
            onClick={(e: React.MouseEvent) => {
              e.stopPropagation();
              onClickRemove(id);
            }}
            sx={{
              alignSelf: 'flex-end',
            }}
          >
            <Trash
              style={{
                height: '16px',
                width: '16px',
                visibility: active ? 'visible' : 'hidden',
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Box>
  );
};
