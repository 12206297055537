import React from 'react';

import { Typography, Stack } from '@mui/material';
export const ItsAlmostTimeToParty = () => (
  <Stack direction="column" justifyContent="center" alignItems="center">
    <img
      src="/mirror_ball.png"
      alt="disco ball"
      style={{
        width: '110px',
        height: '110px',
      }}
    />
    <Typography
      variant="body1"
      sx={{
        fontSize: '40px',
        fontWeight: '700',
        lineHeight: '52px',
      }}
    >
      It's almost time to party!
    </Typography>
  </Stack>
);
