import React from 'react';

import { Box, Typography, Stack } from '@mui/material';

import { moneyPrint } from '@pv/common/utils';
import { Choice, Option } from '../ExpressBook/types';

interface ChoiceListProps {
  choice: Choice;
  label: string;
}
export const ChoiceList = ({ choice, label }: ChoiceListProps) => {
  const getOptionLabel = (option: Option) => {
    return (
      <Stack direction="row" gap="16px">
        <Typography variant="body2">{option.name}</Typography>
        {option.price && (
          <Typography variant="body2" color="textSecondary">
            +{moneyPrint(option.price / 100)}
          </Typography>
        )}
      </Stack>
    );
  };
  return (
    <Box>
      <Typography
        variant="subtitle2"
        sx={{
          padding: '5px 0',
        }}
      >
        {label}
      </Typography>
      <Box sx={{ marginLeft: '16px' }}>
        {choice.options.map((option) => (
          <Typography key={option.id} variant="body2">
            {getOptionLabel(option)}
          </Typography>
        ))}
      </Box>
    </Box>
  );
};
