export const getInitialQuantity = (
  unit: string,
  groupSize: number,
  eventDuration: number,
) => {
  switch (unit) {
    case 'item':
      return 1;
    case 'person':
      return groupSize;
    case 'hour':
      return eventDuration;
    default:
      return 1;
  }
};

export const formatPercentageRate = (rate?: number) => {
  if (rate === undefined) {
    return '';
  }
  return `${(rate * 100.0).toFixed(3).replace(/\.?0+$/, '')}%`;
};
