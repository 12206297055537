import styled from 'styled-components';

export const HoverOverlayDiv = styled.div`
  position: absolute;
  z-index: 1;
  width: 100%;
  height: 100%;
  transition: all 0.3s linear;
  opacity: 0;
  background-color: rgb(202, 196, 255);
  border-radius: 50px;
  cursor: pointer;
  pointer-events: none;
  top: -14px;
  left: -4px;
  height: 130%;
  width: 103%;

  &:hover {
    opacity: 0.2;
  }
`;
