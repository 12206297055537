import { z } from 'zod';

/*
 * Constants.
 */

export const eventStyleEnum = z.enum([
  'seated',
  'standing',
  'seated_or_standing',
]);

export const guestRangeEnum = z.enum([
  'any_number',
  '1_to_25',
  '26_to_50',
  '51_to_100',
  '101_to_200',
  '200_or_more',
]);

export const guestRangeValuesMap = Object.freeze({
  any_number: undefined,
  '1_to_25': [1, 25],
  '26_to_50': [26, 50],
  '51_to_100': [51, 100],
  '101_to_200': [101, 200],
  '200_or_more': [200, 999999],
});

/*
 * Types.
 */

export type EventStyle = z.infer<typeof eventStyleEnum>;
export type GuestRange = z.infer<typeof guestRangeEnum>;

/*
 * Helpers.
 */

export const parseEventStyle = (data: any) => eventStyleEnum.parse(data);
export const parseGuestRange = (data: any) => guestRangeEnum.parse(data);
