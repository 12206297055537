import React from 'react';
import { Stack, Typography } from '@mui/material';
import { Option } from '../types';
import { moneyPrint } from '@pv/common/utils';

interface SelectedChoiceSummaryProps {
  name: string;
  options: Option[];
  selectedOptionIds: string[];
}

export const SelectedChoiceSummary = ({
  name,
  options,
  selectedOptionIds,
}: SelectedChoiceSummaryProps) => {
  const selectedOptions = options.filter((option: Option) =>
    selectedOptionIds.includes(option.id),
  );

  if (!selectedOptions.length) return null;
  return (
    <Stack>
      <Typography variant="subtitle2">{name}</Typography>
      {selectedOptions.map((option: Option) => (
        <Typography key={option.id} variant="caption">
          {option.price
            ? [option.name, moneyPrint((option.price || 0) / 100)].join(' - ')
            : option.name}
        </Typography>
      ))}
    </Stack>
  );
};
