import React, { FC } from 'react';
import {
  PaymentForm as SquarePaymentFormProvider,
  CreditCard,
} from 'react-square-web-payments-sdk';

import { PVRed } from '@pv/common/colors';

import type { SquareTokenData } from '../types/components/square_credit_card_form_types';

const pvEnv = process.env.REACT_APP_PV_ENV;

/*
 * Types.
 */

interface SquareCreditCardFormProps {
  squareLocationId: string;
  confirmButtonText: string;
  onSubmit: (token: SquareTokenData) => void;
  loading: boolean;
}

/*
 * Components.
 */

export const SquareCreditCardForm: FC<SquareCreditCardFormProps> = ({
  squareLocationId,
  confirmButtonText,
  onSubmit,
  loading,
}) => {
  const applicationId =
    pvEnv === 'production'
      ? 'sq0idp-VgrGX8DZAKkN-1mrYCtgDA'
      : 'sandbox-sq0idb-NzIbjSolt87fivt9TNKxFQ';

  return (
    <SquarePaymentFormProvider
      applicationId={applicationId}
      locationId={squareLocationId}
      cardTokenizeResponseReceived={onSubmit}
    >
      <CreditCard
        buttonProps={{
          css: {
            backgroundColor: PVRed,
          },
          isLoading: loading,
        }}
        includeInputLabels
      >
        {confirmButtonText}
      </CreditCard>
    </SquarePaymentFormProvider>
  );
};
