import React from 'react';
import { Box } from '@mui/material';

interface TotalsRowProps {
  children: React.ReactNode[];
}

export const TotalsRow = ({ children }: TotalsRowProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      {children}
    </Box>
  );
};
