import React, { FC } from 'react';
import { SvgIcon } from '@mui/material';

import type { IconAssetProps } from '../../types/components/icon_types';

export const Menu: FC<IconAssetProps> = ({ size, sx }: IconAssetProps) => {
  const style = size ? { width: `${size}px` } : {};

  return (
    <SvgIcon sx={{ ...style, ...sx }}>
      <path
        id="Ellipse 20"
        d="M11.5 2.5V6.87143C11.5 8.52828 10.1569 9.87143 8.5 9.87143C6.84315 9.87143 5.5 8.52828 5.5 6.87143V2.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        id="Vector 16"
        d="M8.5 2.5V21.5"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        id="Vector 17"
        d="M16.5 21.5V15M16.5 15L16.5 3.63278C16.5 3.03128 17.0365 2.5516 17.5952 2.77434C18.9286 3.30588 20.5 4.77796 20.5 9C20.5 14 17.8333 15 16.5 15Z"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        fill="none"
      />
    </SvgIcon>
  );
};
