import React from 'react';
import { Stack } from '@mui/material';

import { Choice, Option } from '../types';
import { SelectedChoiceSummary } from './SelectedChoiceSummary';

interface ContractItemChoicesProps {
  choices?: Choice[];
  addons?: Option[];
  optionIds?: string[];
  addonIds?: string[];
}
export const ContractItemChoices = ({
  choices = [],
  addons = [],
  optionIds = [],
  addonIds = [],
}: ContractItemChoicesProps) => {
  return (
    <Stack>
      {choices.map((choice: Choice) => (
        <SelectedChoiceSummary
          key={choice.id}
          name={choice.name}
          options={choice.options}
          selectedOptionIds={optionIds}
        />
      ))}
      <SelectedChoiceSummary
        name="Add-ons"
        options={addons}
        selectedOptionIds={addonIds}
      />
    </Stack>
  );
};
