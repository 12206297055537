import React, { FC, useState } from 'react';
import { Dayjs } from 'dayjs';
import { styled as muiStyled } from '@mui/material/styles';
import { Box, Stack, TextField, Popover } from '@mui/material';
import { Calendar } from 'react-feather';

import { PVBlack } from '@pv/common/colors';

import type { VoidFn, Nullable } from '../../../types/common';
import { EventDatePicker } from '../pickers/EventDatePicker';
import { HoverOverlayDiv } from './HoverOverlay';
import type {
  PartialExpressBookSettings,
  HoursOfOperation,
} from '../../../types/models/venue_types';

/*
 * Types.
 */

export interface EventDateSelectorProps {
  eventDate: Nullable<Dayjs>;
  onChangeEventDate: VoidFn<Nullable<Dayjs>>;
  expressBookSettings?: PartialExpressBookSettings;
  hoursOfOperation?: HoursOfOperation;
}

/*
 * Constants.
 */

const StyledEventDateTextField = muiStyled(TextField)({
  input: {
    cursor: 'pointer',
  },

  'input::selection': {
    backgroundColor: 'transparent',
    color: 'inherit',
  },

  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },

  '&:hover + .HoverOverlay': {
    opacity: '0.2',
  },
  '&:focus-within + .HoverOverlay': {
    opacity: '0.2',
  },
});

/*
 * Components.
 */

export const EventDateSelector: FC<EventDateSelectorProps> = ({
  eventDate,
  onChangeEventDate,
  expressBookSettings,
  hoursOfOperation,
}: EventDateSelectorProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      marginLeft="8px"
      position="relative"
      width="100%"
    >
      <Calendar
        color={PVBlack}
        style={{
          marginBottom: '16px',
        }}
      />
      <StyledEventDateTextField
        size="small"
        label="Date"
        value={eventDate?.format('MMM DD, YYYY') ?? 'Any date'}
        InputProps={{
          readOnly: true,
        }}
        onClick={handleClick}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            handleClick(event);
          }
        }}
      />
      <HoverOverlayDiv
        className="HoverOverlay"
        style={{ left: '-12px', width: '102%' }}
      />
      <Popover
        id="event-date-selector-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box padding="24px">
          <EventDatePicker
            eventDate={eventDate}
            onChangeEventDate={onChangeEventDate}
            onClose={handleClose}
            shouldRenderActionBar
            shouldCloseOnChange
            expressBookSettings={expressBookSettings}
            hoursOfOperation={hoursOfOperation}
          />
        </Box>
      </Popover>
    </Stack>
  );
};
