import React, { FC, ReactNode } from 'react';

import type { IconAssetName } from '../types/components/icon_types';
import { Chair, Filters, Menu } from './icons';

/*
 * Types.
 */

interface IconProps {
  name: IconAssetName;
  size?: number;
  sx?: { [key: string]: string };
}

/*
 * Component
 */

export const Icon: FC<IconProps> = ({ name, size, sx }: IconProps) => {
  const iconMap: Record<IconAssetName, ReactNode> = {
    chair: <Chair size={size} sx={sx} />,
    filters: <Filters size={size} sx={sx} />,
    menu: <Menu size={size} sx={sx} />,
  };

  return <>{iconMap[name]}</>;
};
