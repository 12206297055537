import React, { useState } from 'react';
import { MenuComponents as Menu } from '@pv/common/components';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Button,
  Stack,
  Typography,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { X as CloseIcon } from 'react-feather';
import { moneyPrint } from '@pv/common/utils';
import { QuantityInput } from './QuantityInput';
import { ChoiceList } from './ChoiceList';
import { ContractItem } from '../types';

const getChoiceLabel = (choice: Menu.Types.Choice) => {
  const { name, limit } = choice;
  return limit && limit > 0 ? `Choose ${limit} ${name}` : `Choose ${name}`;
};
interface SelectOptionsModalProps {
  open: boolean;
  image?: string;
  contractItem: ContractItem;
  onSubmit: (contractItem: ContractItem) => void;
  onClose: () => void;
}

export const SelectOptionsModal = ({
  open,
  image,
  contractItem,
  onSubmit,
  onClose,
}: SelectOptionsModalProps) => {
  const [potentialContractItem, setPotentialContractItem] =
    useState<ContractItem>({ ...contractItem });
  const theme = useTheme();
  const expandToFullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  React.useEffect(() => {
    setPotentialContractItem({ ...contractItem });
  }, [contractItem]);

  const contractItemPrice = React.useMemo(() => {
    let price = (potentialContractItem.basePrice || 0) * potentialContractItem.quantity;

    potentialContractItem.optionIds.forEach((optionId) => {
      const choiceOption = potentialContractItem.choices
        .map((choice) => choice.options)
        .flat()
        .find((option) => option.id === optionId);
      if (choiceOption && choiceOption.price) {
        const additionalPrice = choiceOption.price * (potentialContractItem.quantitiesOnExtrasEnabled ? (potentialContractItem.optionQuantities[optionId] || 0) : potentialContractItem.quantity)
        price += additionalPrice;
      }
    });

    potentialContractItem.addonIds.forEach((addonId) => {
      const addon = potentialContractItem.addons.find(
        (addon) => addon.id === addonId,
      );
      if (addon && addon.price) {
        const additionalPrice = addon.price * (potentialContractItem.quantitiesOnExtrasEnabled ? (potentialContractItem.addonQuantities[addonId] || 0) : potentialContractItem.quantity)
        price += additionalPrice;
      }
    });

    return price / 100
  }, [
    potentialContractItem.quantity,
    potentialContractItem.addonIds,
    potentialContractItem.optionIds,
    potentialContractItem.basePrice,
    potentialContractItem.choices,
    potentialContractItem.addons,
    potentialContractItem.addonQuantities,
    potentialContractItem.optionQuantities,
    potentialContractItem.quantitiesOnExtrasEnabled
  ]);

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onSubmit(potentialContractItem);
    onClose();
  };

  const optionTotal = potentialContractItem.optionIds.reduce(
    (acc, key) => acc + (potentialContractItem.optionQuantities[key] || 0),
    0
  )

  return (
    <Dialog
      open={open}
      onClose={onClose}
      scroll="paper"
      maxWidth={'md'}
      fullScreen={expandToFullScreen}
    >
      <DialogTitle sx={{ flexShrink: 0 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              fontSize: '24px',
              lineHeight: '32px',
            }}
          >
            {potentialContractItem.name}
          </Typography>
          <IconButton onClick={onClose} aria-label="close">
            <CloseIcon />
          </IconButton>
        </Stack>
      </DialogTitle>
      <DialogContent
        sx={{ overflow: 'auto', flexGrow: 1, padding: 0, width: '645px' }}
      >
        <Stack
          direction="column"
          gap="20px"
          sx={{
            overflowY: 'auto',
            padding: '0px 20px 20px 20px',
            maxWidth: '100%',
            flexGrow: 1,
          }}
        >
          {image && (
            <img
              src={image}
              alt={contractItem.name}
              style={{
                width: '100%',
                aspectRatio: '8 / 5',
                objectFit: 'cover',
                borderRadius: '6px',
              }}
            />
          )}
          <Stack gap="5px">
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 700,
                fontSize: '20px',
                lineHeight: '20px',
              }}
            >
              {potentialContractItem.basePrice &&
                moneyPrint(potentialContractItem.basePrice / 100)}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontWeight: 400,
                fontSize: '14px',
                lineHeight: '14px',
              }}
            >
              {potentialContractItem.unit &&
                `Per ${potentialContractItem.unit}`}
            </Typography>
          </Stack>

          <Typography variant="body1">
            {potentialContractItem.details}
          </Typography>

          {potentialContractItem.choices?.map((choice) => (
            <ChoiceList
              key={choice.id}
              label={getChoiceLabel(choice)}
              choice={choice}
              selectedOptionIds={potentialContractItem.optionIds}
              setSelectedOptionIds={(optionIds: string[]) => {
                setPotentialContractItem((pci) => ({
                  ...pci,
                  optionIds: optionIds,
                }));
              }}
              showQuantities={contractItem.quantitiesOnExtrasEnabled}
              optionQuantities={potentialContractItem.optionQuantities}
              setOptionQuantity={(id, v) => {
                setPotentialContractItem((pci) => ({
                  ...pci,
                  optionQuantities: {
                    ...pci.optionQuantities,
                    [id]: v
                  }
                }))
              }}
              unit={potentialContractItem.unit}
              optionTotal={optionTotal}
              defaultQuantity={potentialContractItem.unit === 'item' ? 1 : null}
            />
          ))}
          {potentialContractItem.addons?.length > 0 && (
            <ChoiceList
              choice={{
                id: 'addons',
                name: 'Add-ons',
                options: potentialContractItem.addons,
              }}
              label="Add-ons"
              selectedOptionIds={potentialContractItem.addonIds}
              setSelectedOptionIds={(addonIds: string[]) =>
                setPotentialContractItem((pci) => ({
                  ...pci,
                  addonIds: addonIds,
                }))
              }
              showQuantities={contractItem.quantitiesOnExtrasEnabled}
              optionQuantities={potentialContractItem.addonQuantities}
              setOptionQuantity={(id, v) => {
                setPotentialContractItem((pci) => ({
                  ...pci,
                  addonQuantities: {
                    ...pci.addonQuantities,
                    [id]: v
                  }
                }))
              }}
              unit={potentialContractItem.unit}
              defaultQuantity={potentialContractItem.unit === 'item' ? 1 : potentialContractItem.quantity}
            />
          )}
        </Stack>
      </DialogContent>
      <DialogActions sx={{ flexShrink: 0, padding: 0 }}>
        <Stack
          alignItems="center"
          justifyContent="space-between"
          padding="20px"
          gap="40px"
          sx={{
            width: '100%',
            flexShrink: 0,
            alignSelf: 'flex-end',
            boxShadow: '0px 1px 0px 0px #0000001A inset',
            flexDirection: {
              xs: 'column',
              sm: 'row',
            },
            padding: {
              xs: '20px 20px 40px 20px',
              sm: '20px',
            },
          }}
        >
          <QuantityInput
            label="Qty: "
            quantity={potentialContractItem.quantity}
            setQuantity={(qty) =>
              setPotentialContractItem((pci) => ({
                ...pci,
                quantity: qty,
              }))
            }
          />

          <Button
            variant="contained"
            size="large"
            color="secondary"
            onClick={handleSubmit}
          >
            <Typography
              sx={{
                fontWeight: 700,
                fontSize: '16px',
                lineHeight: '26px',
                color: 'white',
              }}
            >
              {contractItem.id ? 'Update Cart' : 'Add to Order'} -{' '}
              {moneyPrint(contractItemPrice)}
            </Typography>
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};
