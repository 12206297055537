import React, { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';

import { EventStyleSelector } from '../selectors/EventStyleSelector';
import { GuestRangeSelector } from '../selectors/GuestRangeSelector';
import { LocationSelector } from '../selectors/LocationSelector';
import type { FilterBarProps } from '.';

/*
 * Components.
 */

export const WebFilterBar: FC<FilterBarProps> = ({
  locationQuery,
  onChangeLocationQuery,
  eventStyle,
  onChangeEventStyle,
  guestRange,
  onChangeGuestRange,
}: FilterBarProps) => (
  <Box
    role="search"
    height="70px"
    sx={{
      width: '100%',
      maxWidth: '1200px',
      padding: '16px 16px 0px',
      margin: '24px',

      borderRadius: '50px',
      border: '1px solid #E6E6E6',
      background: '#FFF',

      boxShadow: '0px 2px 14px 0px rgba(0, 0, 0, 0.10)',

      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '10px',
    }}
  >
    <Stack direction="row" justifyContent="space-between" width={'33%'}>
      <LocationSelector
        locationQuery={locationQuery}
        onChangeLocationQuery={onChangeLocationQuery}
      />
      <Separator />
    </Stack>
    <Stack direction="row" justifyContent="space-between" width={'33%'}>
      <EventStyleSelector
        eventStyle={eventStyle}
        onChangeEventStyle={onChangeEventStyle}
      />
      <Separator />
    </Stack>
    <Stack direction="row" justifyContent="space-between" width={'33%'}>
      <GuestRangeSelector
        guestRange={guestRange}
        onChangeGuestRange={onChangeGuestRange}
      />
    </Stack>
  </Box>
);

const Separator: FC = () => (
  <Divider orientation="vertical" flexItem sx={{ marginBottom: '10px' }} />
);
