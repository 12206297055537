import React, { useState, createContext, useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { searchMarketplaceSpacesQuery } from '../graphql';
import { Space } from '../../../types/models/space_types';
import type { PartialVenue } from '../../../types/models/venue_types';

import { useParams, useNavigate } from 'react-router-dom';

const defaultCity = 'San Francisco, CA';

const SearchResultsContext = createContext<{
  spacesById: Record<string, Space>;
  venuesById: Record<string, PartialVenue>;
  spaceIds: string[];
  loading: boolean;
  center: { latitude: number; longitude: number };
  locationQuery: string;
  setLocationQuery: (locationQuery: string) => void;
}>({
  spacesById: {},
  venuesById: {},
  spaceIds: [],
  loading: false,
  center: { latitude: 39.5, longitude: -98.35 },
  locationQuery: '',
  setLocationQuery: () => {},
});

export const SearchResultsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const navigate = useNavigate();
  const params = useParams();
  const cityParam = params.city || defaultCity;
  const city = cityParam.replace(/-/g, ' ');
  const [locationQuery, setLocationQuery] = useState<string>(city);

  const [spaceIds, setSpaceIds] = useState<string[]>([]);
  const [spacesById, setSpacesById] = useState<Record<string, Space>>({});
  const [venuesById, setVenuesById] = useState<Record<string, PartialVenue>>(
    {},
  );

  const { data: spacesData, loading: spacesLoading } = useQuery(
    searchMarketplaceSpacesQuery,
    {
      variables: {
        query: locationQuery,
      },
      skip: !locationQuery,
      onCompleted: (data) => {
        const spaces = data?.marketplaceSearch.spaces || [];
        const venues = data?.marketplaceSearch.venues || [];
        const venuesMap = venues.reduce(
          (acc: Record<string, PartialVenue>, venue: PartialVenue) => {
            acc[venue.slug || ''] = venue;
            return acc;
          },
          venuesById,
        );
        const spacesMap = spaces.reduce(
          (acc: Record<string, Space>, space: Space) => {
            acc[space.slug] = {
              ...space,
              venue: venuesById[space.venue.slug || ''],
            };
            return acc;
          },
          spacesById,
        );
        setSpacesById(spacesMap);
        setVenuesById(venuesMap);
        setSpaceIds(spaces.map((space: Space) => space.slug));
      },
    },
  );

  useEffect(() => {
    if (locationQuery === '') {
      return;
    }
    const formattedQuery = locationQuery.replace(/ /g, '-').replace(/,/g, '');
    if (formattedQuery === cityParam) {
      return;
    }
    navigate(`/cities/${formattedQuery}`, { replace: false });
  }, [locationQuery]);

  useEffect(() => {
    setLocationQuery(cityParam.replace(/-/g, ' '));
  }, [cityParam]);

  const center = useMemo(() => {
    const center = spacesData?.marketplaceSearch.center;
    return center || { latitude: 39.5, longitude: -98.35 };
  }, [spacesData]);

  return (
    <SearchResultsContext.Provider
      value={{
        spacesById,
        venuesById,
        spaceIds,
        loading: spacesLoading,
        center,
        locationQuery,
        setLocationQuery,
      }}
    >
      {children}
    </SearchResultsContext.Provider>
  );
};

export const useSearchResults = () => React.useContext(SearchResultsContext);
