import React, { FC } from 'react';
import type { SelectChangeEvent } from '@mui/material';
import { Stack } from '@mui/material';

import { Icon } from '../../Icon';
import type { EventStyle } from '../../../types/components/filter_bar_types';
import type { VoidFn } from '../../../types/common';
import { EventStylePicker } from '../pickers/EventStylePicker';
import { HoverOverlayDiv } from './HoverOverlay';

/*
 * Types.
 */

export interface EventStyleSelectorProps {
  eventStyle: EventStyle;
  onChangeEventStyle: VoidFn<SelectChangeEvent<EventStyle>>;
}

/*
 * Components.
 */

export const EventStyleSelector: FC<EventStyleSelectorProps> = ({
  eventStyle,
  onChangeEventStyle,
}: EventStyleSelectorProps) => (
  <Stack
    direction="row"
    alignItems="center"
    width="100%"
    position="relative"
    sx={{
      marginRight: '10px',

      '&:hover .HoverOverlay': {
        opacity: '0.2',
      },
      '&:focus-within .HoverOverlay': {
        opacity: '0.2',
      },
    }}
  >
    <div
      style={{
        marginBottom: '8px',
        marginLeft: '8px',
      }}
    >
      <Icon name="chair" />
    </div>
    <EventStylePicker
      shouldShowOutlineSelector={false}
      shouldRenderIcon={false}
      eventStyle={eventStyle}
      onChangeEventStyle={onChangeEventStyle}
    />
    <HoverOverlayDiv className="HoverOverlay" />
  </Stack>
);
