import React, { FC } from 'react';
import styled from 'styled-components';
import {
  PickersLayout,
  StaticDatePicker,
  PickersActionBarProps,
  LocalizationProvider,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button } from '@mui/material';

import { PVNavy } from '@pv/common/colors';

import type { Procedure } from '../../../types/common';
import type { EventDateSelectorProps } from '../selectors/EventDateSelector';
import { useShouldDisableDate } from '../../../helpers/dateHelpers';

/*
 * Types.
 */

interface EventDatePickerProps extends EventDateSelectorProps {
  onClose: Procedure;
  shouldRenderActionBar: boolean;
  shouldCloseOnChange: boolean;
}

/*
 * Constants.
 */

const StyledPickersLayout = styled(PickersLayout)({
  '& .MuiPickersToolbar-root': {
    backgroundColor: PVNavy,
    color: '#FFFFFF',

    '& .MuiTypography-root': {
      color: '#FFFFFF',
    },
  },
});

/*
 * Components.
 */

export const EventDatePicker: FC<EventDatePickerProps> = ({
  eventDate,
  onChangeEventDate,
  onClose,
  shouldRenderActionBar,
  shouldCloseOnChange,
  expressBookSettings,
  hoursOfOperation,
}: EventDatePickerProps) => {
  const shouldDisableDate = useShouldDisableDate(
    hoursOfOperation,
    expressBookSettings?.minLeadTimeDays,
    expressBookSettings?.maxLeadTimeDays
  );

  const CustomActionBar = (props: PickersActionBarProps) => {
    const { onClear, className } = props;

    return (
      <Button
        sx={{
          width: '90px',
          textTransform: 'none',
          color: PVNavy,
          borderRadius: '6px',
          border: '1px solid rgba(59, 57, 108, 0.15)',
          display: 'flex',
          justifyContent: 'center',
        }}
        className={className}
        onClick={() => {
          onChangeEventDate(null);
          onClear();
        }}
      >
        Any date
      </Button>
    );
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StaticDatePicker
        value={eventDate}
        shouldDisableDate={shouldDisableDate}
        onChange={(v) => {
          onChangeEventDate(v);
          if (shouldCloseOnChange) {
            onClose();
          }
        }}
        slots={{
          layout: StyledPickersLayout,
          actionBar: shouldRenderActionBar ? CustomActionBar : undefined,
        }}
        slotProps={{
          actionBar: {
            actions: shouldRenderActionBar ? ['clear'] : [],
          },
        }}
      />
    </LocalizationProvider>
  );
};
