import React from 'react';

import { Typography, Stack } from '@mui/material';
import { moneyPrint } from '@pv/common/utils';
interface ContractItemProps {
  price: number;
  quantity: number;
  total: number;
  name: string;
}

export const ContractItem = ({
  price,
  quantity,
  total,
  name,
}: ContractItemProps) => {
  return (
    <Stack
      spacing={2}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Stack direction="column" justifyContent="flex-start">
        <Typography variant="subtitle1">{name}</Typography>
        <Typography variant="body2">{moneyPrint(price / 100)}</Typography>
      </Stack>
      <Stack spacing={2} direction="row" justifyContent="flex-start">
        <Typography variant="body2" sx={{ textAlign: 'right' }}>
          x{quantity}
        </Typography>
        <Typography
          variant="body1"
          sx={{ minWidth: '100px', textAlign: 'right' }}
        >
          {moneyPrint(total / 100)}
        </Typography>
      </Stack>
    </Stack>
  );
};
