import React from 'react';
import { Typography, Stack } from '@mui/material';
import { Zap } from 'react-feather';
import { PVRed } from '@pv/common/colors';
import { PinkOmbreContainer, InfoContainer } from '../styledComponents';

export const HowExpressBookWorks = () => {
  return (
    <PinkOmbreContainer>
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          gap: {
            xs: '8px',
            sm: '16px',
          },
        }}
      >
        <Zap color={PVRed} />
        <Typography
          variant="h2"
          sx={{
            fontSize: {
              xs: '16px',
              sm: '18px',
            },
            fontWeight: '500',
            lineHeight: '150%',
          }}
        >
          How Express Book Works
        </Typography>
      </Stack>
      <Typography
        variant="body1"
        textAlign="left"
        sx={{
          fontSize: {
            xs: '14px',
            sm: '16px',
          },
        }}
      >
        You will not be charged until the venue accepts your request. The venue
        has <b>24 hours</b> to accept or decline.
      </Typography>
    </PinkOmbreContainer>
  );
};
