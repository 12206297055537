import React from 'react';
import { Box, IconButton, SxProps } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { ChevronLeft, ChevronRight } from 'react-feather';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

const ImageCarouselNavButton = ({
  onClick,
  orientation,
}: {
  onClick: () => void;
  orientation: 'left' | 'right';
}) => {
  const sx: SxProps = {
    position: 'absolute',
    top: '45%',
    zIndex: 2,
  };

  if (orientation === 'right') {
    sx.right = 0;
  } else {
    sx.left = 0;
  }

  return (
    <IconButton sx={sx} onClick={() => onClick()}>
      {orientation === 'right' ? (
        <ChevronRight color="white" size="40" />
      ) : (
        <ChevronLeft color="white" size="40" />
      )}
    </IconButton>
  );
};

export const Image = ({
  src,
  sx,
  alt,
}: {
  src: string;
  sx: SxProps;
  alt: string;
}) => {
  return (
    <Box
      component="img"
      sx={{
        width: '100%',
        objectFit: 'cover',
        borderRadius: '16px',
        ...sx,
      }}
      alt={alt}
      src={src}
    />
  );
};

export const ImageCarousel = ({
  children,
}: {
  children: React.ReactChild[];
}) => {
  return (
    <Carousel
      showThumbs={false}
      showIndicators={false}
      showStatus={false}
      infiniteLoop
      renderArrowPrev={(onClick) => {
        if (children.length > 1) {
          return (
            <ImageCarouselNavButton orientation="left" onClick={onClick} />
          );
        }
      }}
      renderArrowNext={(onClick) => {
        if (children.length > 1) {
          return (
            <ImageCarouselNavButton orientation="right" onClick={onClick} />
          );
        }
      }}
    >
      {children}
    </Carousel>
  );
};
