import React from 'react';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { offsetToTimeString } from '@pv/common/utils';

import { BookingAvailability } from './types';
import { ControlledTimeSelector } from '../../../../components/FilterBar/pickers/EventTimePicker';

export const StartTimePicker = ({
  availability,
  open,
  onOpen,
  onClose,
  value,
  disabled,
  onChange,
}: {
  availability: BookingAvailability[];
  open: boolean;
  value: number | null;
  onOpen: () => void;
  onClose: () => void;
  disabled: boolean;
  onChange: (e: SelectChangeEvent<number>) => void;
}) => {
  return (
    <ControlledTimeSelector
      label="Start Time"
      timeOffset={value}
      onChangeTimeOffset={onChange}
      disabled={disabled}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      placeholder="Start"
      showIcon
      id="availability-form-start-time"
    >
      {availability?.map((availability: BookingAvailability) => {
        const timeString = offsetToTimeString(availability.offset);
        return (
          <MenuItem
            key={timeString}
            value={availability.offset}
            disabled={!availability.available}
          >
            {timeString}
          </MenuItem>
        );
      })}
    </ControlledTimeSelector>
  );
};
