import React, { useMemo } from 'react';
import { Button } from '@mui/material';
import { Dayjs } from 'dayjs';

interface Values {
  startOffset?: number;
  endOffset?: number;
  date?: Dayjs;
  origin?: string;
}

const useBookingHref = (values: Values, venueSlug: string, spaceSlug: string) =>
  useMemo(() => {
    const searchParams = new URLSearchParams({
      startDate: values.date?.toISOString() || '',
      startOffset: values.startOffset?.toString() || '',
      endOffset: values.endOffset?.toString() || '',
      origin: values.origin || '',
    });

    return `/venues/${venueSlug}/spaces/${spaceSlug}/bookings/new?${searchParams.toString()}`;
  }, [
    values.date,
    values.startOffset,
    values.endOffset,
    values.origin,
    venueSlug,
    spaceSlug,
  ]);

export const ActionButton = ({
  values,
  venueSlug,
  spaceSlug,
  onInvalid,
}: {
  values: Values;
  venueSlug: string;
  spaceSlug: string;
  onInvalid: () => void;
}) => {
  const valid = values.date && values.startOffset && values.endOffset;
  const bookingHref = useBookingHref(values, venueSlug, spaceSlug);

  return valid ? (
    <Button
      variant="contained"
      color="secondary"
      href={bookingHref}
      size="large"
      sx={{
        padding: '12px 28px',
        fontSize: '16px',
      }}
    >
      Request to Book
    </Button>
  ) : (
    <Button
      variant="contained"
      color="secondary"
      onClick={onInvalid}
      size="large"
      sx={{
        padding: '12px 28px',
        fontSize: '16px',
      }}
    >
      Check Availability
    </Button>
  );
};
