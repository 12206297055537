export const sortImagesToTop = (a: any, b: any) => {
  const imagesA = a.images?.length;
  const imagesB = b.images?.length;

  if (imagesA > imagesB) {
    return -1;
  } else if (imagesA < imagesB) {
    return 1;
  }
  return 0;
};
