import React, { FC } from 'react';
import { invert } from 'lodash';
import { Stack } from '@mui/material';
import type { SelectChangeEvent } from '@mui/material';
import { Users } from 'react-feather';

import type { VoidFn } from '../../../types/common';
import type { GuestRange } from '../../../types/components/filter_bar_types';
import { GuestRangePicker } from '../pickers/GuestRangePicker';
import { HoverOverlayDiv } from './HoverOverlay';

/*
 * Types.
 */

export interface GuestRangeSelectorProps {
  guestRange: GuestRange;
  onChangeGuestRange: VoidFn<SelectChangeEvent<GuestRange>>;
}

/*
 * Constants.
 */

export const displayNameToGuestRangeMap: { [key: string]: GuestRange } = {
  'Any number of guests': 'any_number',
  '1-25 guests': '1_to_25',
  '26-50 guests': '26_to_50',
  '51-100 guests': '51_to_100',
  '101-200 guests': '101_to_200',
  '200+ guests': '200_or_more',
};

export const guestRangeToDisplayNameMap = invert(displayNameToGuestRangeMap);

/*
 * Components.
 */

export const GuestRangeSelector: FC<GuestRangeSelectorProps> = ({
  guestRange,
  onChangeGuestRange,
}: GuestRangeSelectorProps) => (
  <Stack
    direction="row"
    alignItems="center"
    width="100%"
    position="relative"
    sx={{
      '&:hover .HoverOverlay': {
        opacity: '0.2',
      },
      '&:focus-within .HoverOverlay': {
        opacity: '0.2',
      },
    }}
  >
    <Users style={{ marginBottom: '16px', marginLeft: '16px' }} />
    <GuestRangePicker
      guestRange={guestRange}
      onChangeGuestRange={onChangeGuestRange}
      shouldShowOutlineSelector={false}
      shouldRenderIcon={false}
    />
    <HoverOverlayDiv className="HoverOverlay" />
  </Stack>
);
