import React, { useEffect, useMemo, useState } from 'react';
import { Stack, Typography } from '@mui/material';
import { Zap } from 'react-feather';
import dayjs, { Dayjs } from 'dayjs';
import { useFormik } from 'formik';
import { gql, useQuery } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';

import type { Venue } from '../../../../types/models/venue_types';
import type { Space } from '../../../../types/models/space_types';

import { DatePicker } from './DatePicker';
import { ActionButton } from './ActionButton';
import { StartTimePicker } from './StartTimePicker';
import { EndTimePicker } from './EndTimePicker';
import { PVRed } from '@pv/common/colors';

const spaceBookingTimesQuery = gql`
  query SpaceBookableTimes(
    $venueSlug: String!
    $spaceSlug: String!
    $eventDate: ISO8601Date!
  ) {
    spaceBookableTimes(
      venueSlug: $venueSlug
      spaceSlug: $spaceSlug
      eventDate: $eventDate
    ) {
      availability {
        offset
        available
      }
    }
  }
`;

const spaceBookingEndTimesQuery = gql`
  query SpaceBookableEndTimes(
    $venueSlug: String!
    $spaceSlug: String!
    $eventDate: ISO8601Date!
    $startOffset: Int!
  ) {
    spaceBookableEndTimes(
      venueSlug: $venueSlug
      spaceSlug: $spaceSlug
      eventDate: $eventDate
      startOffset: $startOffset
    ) {
      availability {
        offset
        available
      }
    }
  }
`;

const AvailableForExpressBookChip = () => (
  <Stack
    sx={{
      background: 'linear-gradient(94deg, #FFE6CF 4.44%, #FFD2F8 95.56%)',
      padding: '8px 16px',
      borderRadius: '20px',
      flexDirection: 'row',
      gap: '8px',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <Zap color={PVRed} />
    <Typography variant="subtitle2">Available For Express Book</Typography>
  </Stack>
);

export const AvailabilityForm = ({
  space,
  venue,
}: {
  space: Space;
  venue: Venue;
}) => {
  const [searchParams] = useSearchParams();
  const [datePickerOpen, setDatePickerOpen] = useState(false);
  const [startTimeOpen, setStartTimeOpen] = useState(false);
  const [endTimeOpen, setEndTimeOpen] = useState(false);

  const initialDate = searchParams.get('eventDate')
    ? dayjs(searchParams.get('eventDate'))
    : undefined;
  const initialStartOffset = searchParams.get('startOffset')
    ? Number(searchParams.get('startOffset'))
    : undefined;
  const initialEndOffset = searchParams.get('endOffset')
    ? Number(searchParams.get('endOffset'))
    : undefined;

  const formik = useFormik<{
    date?: Dayjs;
    startOffset?: number;
    endOffset?: number;
  }>({
    initialValues: {
      date: initialDate,
      startOffset: initialStartOffset,
      endOffset: initialEndOffset,
    },
    onSubmit() {},
  });

  const { data: spaceBookableTimesData } = useQuery(spaceBookingTimesQuery, {
    variables: {
      spaceSlug: space.slug,
      venueSlug: venue.slug,
      eventDate: formik.values.date,
    },
    skip: !formik.values.date,
  });

  const { data: spaceBookableEndTimesData } = useQuery(
    spaceBookingEndTimesQuery,
    {
      variables: {
        spaceSlug: space.slug,
        venueSlug: venue.slug,
        eventDate: formik.values.date,
        startOffset: formik.values.startOffset,
      },
      skip: !formik.values.startOffset,
    },
  );

  const availability = useMemo(
    () => spaceBookableTimesData?.spaceBookableTimes.availability || [],
    [spaceBookableTimesData?.spaceBookableTimes.availability],
  );

  const endTimeAvailability = useMemo(
    () => spaceBookableEndTimesData?.spaceBookableEndTimes.availability || [],
    [spaceBookableEndTimesData?.spaceBookableEndTimes.availability],
  );

  const availableForExpressBook = space.expressBookEnabled;

  return (
    <form role="search">
      <Stack spacing={3}>
        <DatePicker
          hoursOfOperation={venue.hoursOfOperation}
          minLeadTimeDays={venue.expressBookSettings.minLeadTimeDays}
          maxLeadTimeDays={venue.expressBookSettings.maxLeadTimeDays}
          open={datePickerOpen}
          onOpen={() => {
            setDatePickerOpen(true);
          }}
          onClose={() => {
            setDatePickerOpen(false);
          }}
          value={formik.values.date}
          onChange={(val: Dayjs | null) => {
            formik.setFieldValue('date', val);

            // Clear the start/end time when the date selection changes
            formik.setFieldValue('startOffset', null);
            formik.setFieldValue('endOffset', null);
          }}
        />

        <Stack spacing={3} direction={'row'}>
          <StartTimePicker
            availability={availability}
            open={startTimeOpen}
            onChange={(e) =>
              formik.setFieldValue('startOffset', e.target.value)
            }
            onClose={() => {
              setStartTimeOpen(false);
            }}
            onOpen={() => {
              setStartTimeOpen(true);
            }}
            value={formik.values.startOffset || null}
            disabled={!formik.values.date}
          />
          <EndTimePicker
            availability={endTimeAvailability}
            open={endTimeOpen}
            onOpen={() => {
              setEndTimeOpen(true);
            }}
            onClose={() => {
              setEndTimeOpen(false);
            }}
            startOffset={formik.values.startOffset}
            value={formik.values.endOffset || null}
            onChange={(e) => formik.setFieldValue('endOffset', e.target.value)}
          />
        </Stack>

        <ActionButton
          values={{
            ...formik.values,
            ...(availableForExpressBook && { origin: 'express_book' }),
          }}
          venueSlug={venue.slug}
          spaceSlug={space.slug}
          onInvalid={() => {
            // Go to the first empty field in the form

            if (!formik.values.date) {
              return setDatePickerOpen(true);
            }

            if (!formik.values.startOffset) {
              return setStartTimeOpen(true);
            }

            if (!formik.values.endOffset) {
              return setEndTimeOpen(true);
            }
          }}
        />
        {availableForExpressBook && <AvailableForExpressBookChip />}
      </Stack>
    </form>
  );
};
