import React, { useMemo } from 'react';
import { Typography, Grid, Button, TextField, Divider } from '@mui/material';
import { useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import validator from 'validator';
import { z, ZodString, ZodArray, ZodOptional } from 'zod';
import { useMutation } from '@apollo/client';
import { useSearchParams } from 'react-router-dom';
import { utmTrackingDefaultSourceVals } from '@pv/common/utils';
import { CREATE_BOOKING_MUTATION } from '../graphql';
import { Event, CustomField } from '../types';
import { EventInfoFormContainer } from '../styledComponents';
import { Fields } from '@pv/common/components';

interface FormValues {
  message: string;
  email: string;
  firstName: string;
  lastName: string;
  phone: string;
  eventName: string;
  groupSize: string;
  [key: string]: string | string[]; // Allow additional dynamic keys
}

const useEventFormSchema = (
  minGroupSize: number,
  maxGroupSize?: number,
  customFields?: CustomField[],
) =>
  useMemo(() => {
    const addCustomFieldsToSchema = () => {
      const customFieldsSchema: Record<
        string,
        | ZodString
        | ZodArray<ZodString>
        | ZodOptional<ZodString>
        | ZodOptional<ZodArray<ZodString>>
      > = {};
      customFields?.forEach((field) => {
        if (field.dataType === 'multi_enum') {
          if (field.required) {
            customFieldsSchema[field.userFieldKey] = z.array(
              z.string({
                required_error: `${field.name} is required`,
              }),
            );
          } else {
            customFieldsSchema[field.userFieldKey] = z
              .array(z.string())
              .optional();
          }
        } else {
          if (field.required) {
            customFieldsSchema[field.userFieldKey] = z.string({
              required_error: `${field.name} is required`,
            });
          } else {
            customFieldsSchema[field.userFieldKey] = z.string().optional();
          }
        }
      });
      return customFieldsSchema;
    };
    const schema = z.object({
      email: z
        .string({ required_error: 'Email is required' })
        .email({ message: 'Enter a valid email' }),
      firstName: z.string({ required_error: 'First Name is required' }),
      lastName: z.string({ required_error: 'Last Name is required' }),
      phone: z
        .string({ required_error: 'Phone is required' })
        .refine(validator.isMobilePhone, 'Enter a valid phone number'),
      eventName: z.string({ required_error: 'Event Name is required' }),
      message: z.string({ required_error: 'Message is required' }),
      groupSize: z
        .string({ required_error: 'Group Size is required' })
        .refine(validator.isNumeric, 'Group Size must be a number')
        .refine(
          (val) => parseInt(val) >= minGroupSize,
          `This venue has a minimum group size of ${minGroupSize}`,
        )
        .refine(
          (val) => (maxGroupSize ? parseInt(val) <= maxGroupSize : true),
          `This space has a maximum group size of ${maxGroupSize}`,
        ),
      ...addCustomFieldsToSchema(),
    });

    return schema;
  }, [minGroupSize, maxGroupSize, customFields]);

interface Props {
  onCompleted: (event: Event) => void;
  venueSlug: string;
  spaceSlug: string;
  buttonText: string;
  origin: 'express_book' | 'marketplace';
  minGroupSize?: number;
  maxGroupSize?: number;
  customFields?: CustomField[];
}
export const EventInfoForm = ({
  venueSlug,
  spaceSlug,
  onCompleted,
  origin,
  buttonText,
  minGroupSize = 0,
  maxGroupSize,
  customFields,
}: Props) => {
  const [URLSearchParams] = useSearchParams();
  const startDate = URLSearchParams.get('startDate') || '';
  const startOffset = URLSearchParams.get('startOffset') || '';
  const endOffset = URLSearchParams.get('endOffset') || '';
  let utmSource = URLSearchParams.get('utm_source') || '';
  const utmCampaign = URLSearchParams.get('utm_campaign') || '';

  const eventFormSchema = useEventFormSchema(
    minGroupSize,
    maxGroupSize,
    customFields,
  );
  const validationSchema = toFormikValidationSchema(eventFormSchema);

  if (!utmTrackingDefaultSourceVals.includes(utmSource)) {
    utmSource = '';
  }

  // TODO: should this ping a create_marketplace_event mutation instead, and return a marketplace_event?

  const [createEvent] = useMutation(CREATE_BOOKING_MUTATION, {
    onCompleted: ({ createEvent }) => {
      if (createEvent.errors.length > 0) {
        console.error('Error creating event:', createEvent.errors);
      } else {
        onCompleted(createEvent.event);
      }
    },
  });

  const saveInLocalStorage = (values: FormValues) => {
    localStorage.setItem('eventInfo', JSON.stringify(values));
  };

  const getFromLocalStorage = () => {
    const eventInfo = localStorage.getItem('eventInfo');
    if (eventInfo) {
      return JSON.parse(eventInfo);
    }
    return null;
  };

  const maybeSaveInLocalStorage = (values: FormValues) => {
    if (process.env.NODE_ENV !== 'production') {
      saveInLocalStorage(values);
    }
  };

  const maybeGetFromLocalStorage = () => {
    if (process.env.NODE_ENV !== 'production') {
      return getFromLocalStorage() as FormValues;
    }
    return null;
  };

  const handleSubmit = (values: FormValues) => {
    maybeSaveInLocalStorage(values);

    const payload = {
      venueSlug: venueSlug,
      name: values.eventName,
      status: origin === 'express_book' ? 'pending' : 'qualified',
      origin: origin,
      planner: {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        phone: values.phone,
        attemptMatchByEmail: true,
      },
      spaceSlugs: [spaceSlug],
      dateTimeRange: {
        startDate: startDate && new Date(startDate).toISOString(),
        startOffset: parseInt(startOffset),
        endOffset: parseInt(endOffset),
      },
      sendEmails: true,
      utmSource: utmSource,
      utmCampaign: utmCampaign,
      groupSize: parseInt(values.groupSize),
      message: values.message,
      messageOrigin: origin,
      venueUserFields: customFields?.reduce(
        (acc, field) => ({
          ...acc,
          [field.userFieldKey]: values[field.userFieldKey] || '',
        }),
        {},
      ),
    };
    createEvent({
      variables: {
        input: payload,
      },
    });
  };

  const initialValues: FormValues = maybeGetFromLocalStorage() || {
    message: '',
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    eventName: '',
    groupSize: '',
    ...customFields?.reduce(
      (acc, field) => ({ ...acc, [field.userFieldKey]: '' }),
      {},
    ),
  };

  const formik = useFormik<FormValues>({
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
  });
  return (
    <EventInfoFormContainer>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                fontWeight: 700,
                fontSize: '24px',
                lineHeight: '32px',
              }}
            >
              Enter your event details
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="First Name"
              inputProps={{ autoComplete: 'firstName' }}
              {...formik.getFieldProps('firstName')}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              required
              fullWidth
              variant="outlined"
              data-cy="event-info-form-first-name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Last Name"
              inputProps={{ autoComplete: 'lastName' }}
              {...formik.getFieldProps('lastName')}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.lastName && formik.errors.lastName}
              required
              fullWidth
              variant="outlined"
              data-cy="event-info-form-last-name"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              type="email"
              label="Email"
              inputProps={{ autoComplete: 'email' }}
              required
              {...formik.getFieldProps('email')}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              fullWidth
              variant="outlined"
              data-cy="event-info-form-email"
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Phone"
              inputProps={{ autoComplete: 'tel' }}
              required
              {...formik.getFieldProps('phone')}
              error={formik.touched.phone && Boolean(formik.errors.phone)}
              helperText={formik.touched.phone && formik.errors.phone}
              fullWidth
              variant="outlined"
              type="tel"
              data-cy="event-info-form-phone"
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Event Name"
              {...formik.getFieldProps('eventName')}
              error={
                formik.touched.eventName && Boolean(formik.errors.eventName)
              }
              helperText={formik.touched.eventName && formik.errors.eventName}
              fullWidth
              required
              variant="outlined"
              type="text"
              data-cy="event-info-form-event-name"
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Group Size"
              {...formik.getFieldProps('groupSize')}
              error={
                formik.touched.groupSize && Boolean(formik.errors.groupSize)
              }
              helperText={formik.touched.groupSize && formik.errors.groupSize}
              fullWidth
              required
              variant="outlined"
              type="text"
              data-cy="event-info-form-group-size"
            />
          </Grid>
          {customFields?.map((field) => (
            <Grid item xs={12} key={field.userFieldKey}>
              <Fields.PvCustomField
                label={field.name}
                variant="outlined"
                userFieldDefinition={field}
                value={formik.values[field.userFieldKey] || ''}
                onChange={(value: any) => {
                  formik.setFieldValue(field.userFieldKey, value);
                }}
                required={field.required}
                forceValidate={formik.errors[field.userFieldKey]}
                error={
                  formik.touched[field.userFieldKey] &&
                  Boolean(formik.errors[field.userFieldKey])
                }
                helperText={
                  formik.touched[field.userFieldKey] &&
                  formik.errors[field.userFieldKey]
                }
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              label="Tell us about your event"
              {...formik.getFieldProps('message')}
              error={formik.touched.message && Boolean(formik.errors.message)}
              helperText={formik.touched.message && formik.errors.message}
              fullWidth
              variant="outlined"
              required
              multiline
              minRows={8}
              maxRows={30}
              data-cy="event-info-form-message"
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              size="large"
              color="secondary"
              sx={{
                width: '100%',
                textTransform: 'none',
                padding: '12px 28px',
                fontSize: '16px',
              }}
              data-cy="event-info-form-submit"
            >
              {buttonText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </EventInfoFormContainer>
  );
};
