import { Space } from '../../../types/models/space_types';
import type {
  EventStyle,
  GuestRange,
} from '../../../types/components/filter_bar_types';
import { guestRangeValuesMap } from '../../../types/components/filter_bar_types';

import some from 'lodash/some';

export const checkHasImg = (space: Space) => {
  return (
    some(space?.images) ||
    space?.venue?.profileBanner ||
    some(space?.venue?.images)
  );
};

export const checkDisplayInDirectory = (space: Space) => {
  return Boolean(space?.displayInDirectory);
};

export const checkStyle = (space: Space, style: EventStyle) => {
  if (!space) {
    return false;
  }
  const { seated, standing } = space;
  if (style === 'seated_or_standing') {
    return true;
  }

  if (standing && style === 'standing') {
    return true;
  }

  if (seated && style === 'seated') {
    return true;
  }

  return false;
};

export const checkGroupSize = (
  space: Space,
  style: EventStyle,
  groupSize: GuestRange,
) => {
  if (!space) {
    return false;
  }
  if (groupSize === 'any_number') {
    return true;
  }

  const { seated, standing } = space;
  const [min, max] = guestRangeValuesMap[groupSize];
  const minSize = min;
  const maxSize = max;
  if (
    (style === 'standing' || style === 'seated_or_standing') &&
    typeof standing === 'number'
  ) {
    return standing >= minSize && standing <= maxSize;
  }

  if (
    (style === 'seated' || style === 'seated_or_standing') &&
    typeof seated === 'number'
  ) {
    return seated >= minSize && seated <= maxSize;
  }

  return false;
};
