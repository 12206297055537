import React, { FC } from 'react';
import { Stack } from '@mui/material';
import { MapPin } from 'react-feather';
import { PVBlack } from '@pv/common/colors';
import { LocationAutocomplete } from './LocationAutocomplete';
import type { VoidFn } from '../../../types/common';
import { HoverOverlayDiv } from './HoverOverlay';

export interface LocationSelectorProps {
  locationQuery: string;
  onChangeLocationQuery: VoidFn<string>;
}

export const LocationSelector: FC<LocationSelectorProps> = ({
  locationQuery,
  onChangeLocationQuery,
}: LocationSelectorProps) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      marginLeft="8px"
      position="relative"
      width="100%"
    >
      <MapPin
        color={PVBlack}
        style={{
          marginBottom: '16px',
        }}
      />
      <LocationAutocomplete
        locationQuery={locationQuery}
        onChange={onChangeLocationQuery}
      />

      <HoverOverlayDiv className="HoverOverlay" style={{ left: '-12px' }} />
    </Stack>
  );
};
