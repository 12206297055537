import React, { FC, ReactNode } from 'react';
import { Box, Divider, Link, Stack, Typography } from '@mui/material';
import styled from 'styled-components';

import { PVRed, PVBlack } from '@pv/common/colors';

import { FooterBar } from './FooterBar';

/*
 * Types.
 */

interface ProfileFooterProps {}

interface ProfileFooterLinkProps {
  href: string;
  color?: string;
  children: ReactNode;
}

/*
 * Constants.
 */

const StyledAboutStack = styled(Stack)({
  gap: '10px',
});

const ProfileFooterLink: FC<ProfileFooterLinkProps> = ({
  href,
  children,
  color = PVBlack,
}) => (
  <Link
    href={href}
    target="_blank"
    rel="noopener,noreferrer"
    variant="body1"
    underline="hover"
    color={color}
  >
    {children}
  </Link>
);

/*
 * Components.
 */

export const ProfileFooter: FC<ProfileFooterProps> = () => (
  <Stack>
    <Stack direction="row" sx={{ padding: '16px 0px 48px' }}>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        gap="32px"
        width={{ sm: 'auto', md: '100%' }}
        sx={{ justifyContent: 'space-between' }}
      >
        <Stack>
          <Box
            component="img"
            alt="Perfect Venue Logo"
            src="/pv-logo-marketplace.png"
            height="32px"
          />
        </Stack>
        <StyledAboutStack>
          <Typography
            variant="h2"
            sx={{
              fontSize: '16px',
              lineHeight: '150%',
              fontWeight: 500,
            }}
          >
            Perfect Venue
          </Typography>
          <ProfileFooterLink href="https://www.perfectvenue.com/">
            About Perfect Venue
          </ProfileFooterLink>
          <ProfileFooterLink href="https://www.perfectvenue.com/customer-success">
            Customer Success
          </ProfileFooterLink>
          <ProfileFooterLink href="https://www.perfectvenue.com/blog">
            Blog
          </ProfileFooterLink>
        </StyledAboutStack>
        <StyledAboutStack>
          <Typography
            variant="h2"
            sx={{
              fontSize: '16px',
              lineHeight: '150%',
              fontWeight: 500,
            }}
          >
            Contact
          </Typography>
          <ProfileFooterLink href="https://www.perfectvenue.com/contact-us">
            Contact Us
          </ProfileFooterLink>
          <ProfileFooterLink href="https://www.perfectvenue.com/support-request">
            Contact Support
          </ProfileFooterLink>
          <ProfileFooterLink href="tel:(415)906-4190">
            (415) 906-4190
          </ProfileFooterLink>
        </StyledAboutStack>
      </Stack>
    </Stack>
    <Divider />
    <FooterBar />
  </Stack>
);
