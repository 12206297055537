import React from 'react';
import { Popover, Box, Button, Stack, Typography } from '@mui/material';
import { ContractItem as ContractItemType } from '../types';
import { ContractItemImage } from './ContractItemImage';
import { moneyPrint } from '@pv/common/utils';
import { ContractItemChoices } from './ContractItemChoices';
interface SuccessIndicatorProps {
  open: boolean;
  anchorEl: null | HTMLElement;
  onViewCartClick: () => void;
  onClose: () => void;
  contractItem: ContractItemType;
}

export const SuccessIndicator = ({
  open,
  anchorEl,
  onViewCartClick,
  onClose,
  contractItem,
}: SuccessIndicatorProps) => {
  const { name, image, choices, addons, optionIds, addonIds, quantity, total } =
    contractItem;
  const priceString = total ? moneyPrint(total / 100) : '';

  return (
    <Popover
      disableScrollLock
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      sx={{
        top: '16px',
        '& .MuiPopover-paper': {
          borderRadius: '8px',
        },
      }}
    >
      <Stack
        direction="column"
        gap="20px"
        sx={{
          padding: '20px',

          width: '400px',
          maxWidth: '100%',
        }}
      >
        <Stack
          direction="row"
          alignItems={'flex-start'}
          gap="12px"
          flexWrap={'wrap'}
          sx={{
            width: '100%',

            backgroundColor: 'white',
          }}
        >
          {image && <ContractItemImage imageUrl={image} />}
          <Stack
            direction="column"
            justifyContent="flex-start"
            sx={{ flexGrow: 1 }}
          >
            <Stack
              spacing={2}
              direction="row"
              justifyContent="space-between"
              alignContent="flex-end"
              flexWrap={'wrap'}
            >
              <Typography variant="subtitle1">{name}</Typography>

              <Stack direction="row" justifyContent="space-between" spacing={3}>
                <Typography
                  variant="subtitle2"
                  color="text.secondary"
                >{`x${quantity}`}</Typography>
                <Typography variant="subtitle2" sx={{ textAlign: 'right' }}>
                  {priceString}
                </Typography>
              </Stack>
            </Stack>
            <ContractItemChoices
              choices={choices}
              addons={addons}
              optionIds={optionIds}
              addonIds={addonIds}
            />
          </Stack>
        </Stack>
        <Box>
          <Button
            fullWidth
            color="secondary"
            variant="contained"
            onClick={onViewCartClick}
            size="large"
          >
            <Typography
              sx={{
                fontSize: '16px',
                lineHeight: '26px',
                fontWeight: '700',
                color: 'white',
              }}
            >
              View Cart
            </Typography>
          </Button>
        </Box>
      </Stack>
    </Popover>
  );
};
