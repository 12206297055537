import React from 'react';
import { Typography, Link, Stack } from '@mui/material';

export const ListYourVenueLink = () => {
  return (
    <Link
      href="https://www.perfectvenue.com/"
      target="_blank"
      underline="hover"
      marginRight="24px"
    >
      <Stack
        direction="row"
        gap={1}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <img
          src="/store.png"
          alt=""
          style={{
            width: '24px',
            height: '24px',
          }}
        />
        <Typography component="span">List your venue</Typography>
      </Stack>
    </Link>
  );
};
