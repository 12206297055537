import React from 'react';

import { Typography, Stack, Divider, Button, Box } from '@mui/material';
import { Link, useParams } from 'react-router-dom';
import { ItsAlmostTimeToParty } from '../ItsAlmostTimeToParty';
import { NavigationBar } from '../NavigationBar';
import { PageContainer } from '../styledComponents';
import { FooterBar } from '../../../components/FooterBar'

export const MarketplaceEventSuccessPage = () => {
  const { venueSlug } = useParams()
  return (
    <>
      <NavigationBar />
      <PageContainer>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={2}
          sx={{ height: '540px' }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: '30px',
              lineHeight: '34.5px',
              fontWeight: '700',
            }}
          >
            Request Sent
          </Typography>
          <ItsAlmostTimeToParty />
          <Typography
            variant="body1"
            sx={{
              fontSize: '20px',
              lineHeight: '30px',
              fontWeight: '400',
            }}
          >
            The venue will get back to you soon via email.
          </Typography>
          <Box paddingTop='20px'>
            <Button component={Link} to={`/venues/${venueSlug}`} variant='outlined' color='secondary'>Return to Venue</Button>
          </Box>
        </Stack>
        <div style={{ flexGrow: 1 }} />
        <div>
          <Divider />
          <FooterBar />
        </div>
      </PageContainer>
    </>
  )
};
