import React from 'react';

import {
  Checkbox,
  FormControl,
  FormLabel,
  FormGroup,
  Box,
  FormControlLabel,
  Typography,
  Stack,
} from '@mui/material';

import { moneyPrint } from '@pv/common/utils';
import { MenuComponents } from '@pv/common/components'
import { Choice, Option, MenuItemUnit } from '../types';

const { ExtraQuantityInput } = MenuComponents

interface OptionRowProps {
  option: Option;
  onChange: (enabled: boolean) => void;
  selected: boolean;
  quantityEditable: boolean;
  limit?: number;
  unit: MenuItemUnit;
  optionIds: string[];
  optionQuantities: Record<string, number>;
  onChangeQuantity: (v: number) => void;
  disabled: boolean;
}

const OptionRow = ({ option, optionIds, optionQuantities, onChange, onChangeQuantity, selected, quantityEditable, unit, limit, disabled }: OptionRowProps) => {
  const quantity = optionQuantities[option.id]

  const optionTotal = optionIds.reduce(
    (acc, key) => acc + (optionQuantities[key] || 0),
    0
  )

  return (
    <Stack direction='row' justifyContent='space-between'>
      <FormControlLabel
        key={option.id}
        control={
          <Checkbox
            color="secondary"
            checked={selected}
            onChange={(e) => { onChange(e.target.checked) }}
            disabled={disabled}
          />
        }
        label={<Stack direction="row" gap="16px">
          <Typography variant="body2">{option.name}</Typography>
          {option.price && !quantityEditable && (
            <Typography variant="body2" color="textSecondary">
              +{moneyPrint(option.price / 100)}
            </Typography>
          )}
        </Stack>}
      />
      {selected && quantityEditable && (
        <Stack direction='row' alignItems='center' gap='16px'>
          <Box>
            {option.price && (
              <Typography variant="body2">
                +{moneyPrint(option.price / 100)}
              </Typography>
            )}
          </Box>
          <ExtraQuantityInput editable={true} quantity={quantity} unit={unit} limit={limit} optionTotal={optionTotal} onChange={onChangeQuantity} />
          <Box minWidth='80px' textAlign='right'>
            {option.price && !!quantity && (
              <Typography variant="body2" fontWeight='bold'>
                {moneyPrint(option.price * quantity / 100)}
              </Typography>
            )}
          </Box>
        </Stack>
      )}
    </Stack>
  )
}

interface ChoiceListProps {
  choice: Choice;
  label: string;
  selectedOptionIds: string[];
  setSelectedOptionIds: (choiceOptions: string[]) => void;
  showQuantities: boolean;
  optionQuantities: Record<string, number>;
  setOptionQuantity: (id: string, v: number) => void;
  unit: 'person' | 'hour' | 'item',
  optionTotal?: number;
  defaultQuantity: number | null
}
export const ChoiceList = ({
  choice,
  label,
  selectedOptionIds,
  setSelectedOptionIds,
  optionQuantities,
  setOptionQuantity,
  showQuantities,
  unit,
  optionTotal,
  defaultQuantity
}: ChoiceListProps) => {
  const { limit } = choice;

  const relevantSelectedOptionIds = React.useMemo(() => {
    // if there is more than one "choice" on this contract item, the
    // limit only applies to the number of options selected for this
    // particular choice

    return selectedOptionIds.filter((id) =>
      choice.options.map((option) => option.id).includes(id),
    );
  }, [selectedOptionIds, choice.options]);

  const irrelevantSelectedOptionIds = React.useMemo(() => {
    return selectedOptionIds.filter(
      (id) => !choice.options.map((option) => option.id).includes(id),
    );
  }, [selectedOptionIds, choice.options]);

  return (
    <Box>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">
          <Typography
            variant="subtitle2"
            sx={{
              padding: '5px 0',
            }}
          >
            {label}
          </Typography>
        </FormLabel>
        <FormGroup sx={{ marginLeft: '16px' }}>
          {choice.options.map((option) => {
            const selected = relevantSelectedOptionIds.includes(option.id)
            return <OptionRow
              key={option.id}
              selected={selected}
              option={option}
              optionIds={relevantSelectedOptionIds}
              optionQuantities={optionQuantities}
              quantityEditable={showQuantities}
              limit={limit}
              unit={unit}
              onChange={(enabled) => {
                if (enabled) {
                  if (
                    choice.limit &&
                    relevantSelectedOptionIds.length >= choice.limit
                  ) {
                    setSelectedOptionIds([
                      ...irrelevantSelectedOptionIds,
                      ...relevantSelectedOptionIds.slice(1),
                      option.id,
                    ]);
                  } else {
                    setSelectedOptionIds([...selectedOptionIds, option.id]);

                  }
                  if (showQuantities && defaultQuantity) {
                    setOptionQuantity(option.id, defaultQuantity)
                  }
                } else {
                  setSelectedOptionIds(
                    selectedOptionIds.filter((id) => id !== option.id),
                  );
                  if (showQuantities) {
                    setOptionQuantity(option.id, 0)
                  }
                }
              }}
              onChangeQuantity={(v) => {
                setOptionQuantity(option.id, v)
              }}
              disabled={limitReached(showQuantities, unit, relevantSelectedOptionIds, optionTotal || 0, limit) && !selected}
            />
          }
          )}
        </FormGroup>

      </FormControl>
    </Box>
  );
};

function limitReached(quantitiesEnabled: boolean, unit: MenuItemUnit, optionIds: string[], optionTotal: number, limit?: number) {
  if (quantitiesEnabled && unit === 'item') {

    return optionTotal === limit

  }

  return optionIds.length === limit
}
