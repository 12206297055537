import React, { useState, createContext, useMemo } from 'react';
import { useSearchResults } from './SearchResultsProvider';
import { Space } from '../../../types/models/space_types';
import type { PartialVenue } from '../../../types/models/venue_types';
import type {
  EventStyle,
  GuestRange,
} from '../../../types/components/filter_bar_types';
import {
  checkHasImg,
  checkStyle,
  checkGroupSize,
  checkDisplayInDirectory,
} from '../utils/filter';
import { sortImagesToTop } from '../utils/sort';

const FilteredSearchResultsContext = createContext<{
  filteredSpaceIds: string[];
  filteredVenueIds: string[];
  filteredSpaces: Space[];
  filteredVenues: PartialVenue[];
  setGuestRange: (guestRange: GuestRange) => void;
  setEventStyle: (eventStyle: EventStyle) => void;
  eventStyle: EventStyle;
  guestRange: GuestRange;
}>({
  filteredSpaceIds: [],
  filteredVenueIds: [],
  filteredSpaces: [],
  filteredVenues: [],
  setGuestRange: () => {},
  setEventStyle: () => {},
  eventStyle: 'seated_or_standing',
  guestRange: 'any_number',
});
export const FilteredSearchResultsProvider = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => {
  const { spaceIds, spacesById, venuesById, loading } = useSearchResults();
  const [eventStyle, setEventStyle] =
    useState<EventStyle>('seated_or_standing');
  const [guestRange, setGuestRange] = useState<GuestRange>('any_number');

  const filterSpace = (space: any) => {
    const hasImg = checkHasImg(space);
    const hasStyle = checkStyle(space, eventStyle);
    const hasGroupSize = checkGroupSize(space, eventStyle, guestRange);
    const displayInDirectory = checkDisplayInDirectory(space);
    return hasImg && hasStyle && hasGroupSize && displayInDirectory;
  };

  const filteredSpaceIds = useMemo(() => {
    return spaceIds
      .filter((spaceId) => {
        const space = spacesById[spaceId];
        return filterSpace(space);
      })
      .sort((spaceId, spaceId2) => {
        const space = spacesById[spaceId];

        const space2 = spacesById[spaceId2];
        return sortImagesToTop(space, space2);
      });
  }, [spaceIds, spacesById, eventStyle, guestRange]);

  const filteredSpaces = useMemo(() => {
    return filteredSpaceIds.map((spaceId) => spacesById[spaceId]);
  }, [filteredSpaceIds, spacesById]);

  const filteredVenueIds = useMemo(() => {
    return [
      ...new Set(filteredSpaces.map((space) => space.venue.slug)),
    ] as string[];
  }, [filteredSpaces]);

  const filteredVenues = useMemo(() => {
    return filteredVenueIds.map((venueId) => venuesById[venueId]);
  }, [filteredVenueIds, venuesById]);

  return (
    <FilteredSearchResultsContext.Provider
      value={{
        filteredSpaceIds: filteredSpaceIds,
        filteredVenueIds: filteredVenueIds,
        filteredSpaces: filteredSpaces,
        filteredVenues: filteredVenues,
        setGuestRange,
        setEventStyle,
        eventStyle,
        guestRange,
      }}
    >
      {children}
    </FilteredSearchResultsContext.Provider>
  );
};

export const useFilteredSearchResults = () =>
  React.useContext(FilteredSearchResultsContext);
