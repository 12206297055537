import React from 'react';
import { useMutation } from '@apollo/client';
import {
  useStripe,
  useElements,
  PaymentElement,
} from '@stripe/react-stripe-js';
import { useStripeLoader } from '@pv/common/hooks';
import { Elements } from '@stripe/react-stripe-js';
import { useSearchParams } from 'react-router-dom';
import { LoadingIndicator } from '@pv/common/components';

import {
  CREATE_SETUP_INTENT_MUTATION,
  CREATE_STRIPE_PAYMENT_METHOD_MUTATION,
} from '../graphql';

interface StripeCreditCardFormProps {
  plannerId: string;
  onComplete: ({ paymentMethodId }: { paymentMethodId: string }) => void;
  isSubmitting: boolean;
  setIsSubmitting: (isSubmitting: boolean) => void;
  setErrorMessage: (errorMessage: string) => void;
  redirectUrl: string;
  setupIntentId?: string;
}
export const StripeCreditCardFormInternals = ({
  plannerId,
  onComplete,
  isSubmitting,
  setIsSubmitting,
  setErrorMessage,
  redirectUrl,
  setupIntentId,
}: StripeCreditCardFormProps) => {
  const [URLSearchParams] = useSearchParams();
  const stripeClientSecret =
    URLSearchParams.get('setup_intent_client_secret') || '';
  const stripeSetupIntentId = URLSearchParams.get('setup_intent') || '';
  const redirectStatus = URLSearchParams.get('redirect_status') || '';
  const stripe = useStripe();
  const elements = useElements();

  const [createStripePaymentMethod] = useMutation(
    CREATE_STRIPE_PAYMENT_METHOD_MUTATION,
    {
      onCompleted: (data) => {
        console.log('createStripePaymentMethod', data);
        onComplete({
          paymentMethodId: data?.createStripePaymentMethod?.paymentMethod?.id,
        });
      },
    },
  );

  React.useEffect(() => {
    // handle if stripe has redirected us back to this page after confirmSetup
    if (
      !stripe ||
      !stripeClientSecret ||
      !stripeSetupIntentId ||
      !plannerId ||
      !redirectStatus
    ) {
      return;
    }
    if (redirectStatus === 'succeeded') {
      stripe.retrieveSetupIntent(stripeClientSecret).then(({ setupIntent }) => {
        if (!setupIntent) {
          return;
        }
        if (setupIntent.status === 'succeeded') {
          createStripePaymentMethod({
            variables: { input: { plannerId, stripeSetupIntentId } },
          });
        }
      });
    }
  }, [
    stripe,
    stripeClientSecret,
    stripeSetupIntentId,
    plannerId,
    redirectStatus,
  ]);

  React.useEffect(() => {
    if (isSubmitting) {
      sendRequest();
    }
  }, [isSubmitting]);

  const sendRequest = async () => {
    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      return;
    }

    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: redirectUrl,
      },
      redirect: 'if_required',
    });

    if (error && error.message) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message);
      setIsSubmitting(false);
    } else {
      setErrorMessage('');
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
      createStripePaymentMethod({
        variables: { input: { plannerId, stripeSetupIntentId: setupIntentId } },
      });
    }
  };

  return <PaymentElement />;
};

export const StripeCreditCardForm = ({
  plannerId,
  onComplete,
  isSubmitting,
  setIsSubmitting,
  setErrorMessage,
  redirectUrl,
}: StripeCreditCardFormProps) => {
  const stripePromise = useStripeLoader();

  const [URLSearchParams] = useSearchParams();
  const stripeSetupIntentId = URLSearchParams.get('setup_intent') || '';

  const stripeClientSecret =
    URLSearchParams.get('setup_intent_client_secret') || '';
  const [clientSecret, setClientSecret] = React.useState<string | undefined>(
    stripeClientSecret,
  );
  const [setupIntentId, setSetupIntentId] = React.useState<string | undefined>(
    stripeSetupIntentId,
  );

  const [createSetupIntent, { loading }] = useMutation(
    CREATE_SETUP_INTENT_MUTATION,
    {
      onCompleted: (data) => {
        if (data?.createSetupIntent?.stripeClientSecret) {
          setClientSecret(data.createSetupIntent.stripeClientSecret);
          setSetupIntentId(data.createSetupIntent.stripeSetupIntentId);
        }
      },
    },
  );
  React.useEffect(() => {
    if (!plannerId) {
      return;
    }
    if (stripeSetupIntentId) {
      setClientSecret(stripeClientSecret);
      return;
    }
    if (clientSecret) {
      return;
    }
    const input = {
      plannerId,
    };
    createSetupIntent({ variables: { input } });
  }, [plannerId]);

  if (loading || !clientSecret) {
    return <LoadingIndicator />;
  }
  return (
    <Elements
      stripe={stripePromise}
      options={{
        clientSecret: clientSecret,
      }}
    >
      <StripeCreditCardFormInternals
        plannerId={plannerId}
        onComplete={onComplete}
        isSubmitting={isSubmitting}
        setIsSubmitting={setIsSubmitting}
        setErrorMessage={setErrorMessage}
        redirectUrl={redirectUrl}
        setupIntentId={setupIntentId}
      />
    </Elements>
  );
};
