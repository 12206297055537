import React from 'react';
import { Box, Typography, Divider, Stack } from '@mui/material';
import { Calendar, Clock } from 'react-feather';
import { Space, Venue } from './types';
import moment from 'moment-timezone';
import {
  InfoContainer,
  RoundedImageContainer,
  SpaceImage,
  SpaceDetailsContainer,
} from './styledComponents';
import { offsetToTimeString } from '@pv/common/utils';

const EventInfoItem = ({
  icon,
  text,
}: {
  icon: React.ReactNode;
  text: string;
}) => (
  <InfoContainer>
    {icon}
    <Typography
      variant="body1"
      sx={{
        fontSize: {
          xs: '16px',
          sm: '18px',
        },
      }}
    >
      {text}
    </Typography>
  </InfoContainer>
);

interface EventSummaryProps {
  space?: Space;
  venue?: Venue;
  startDate: string;
  startOffset: number;
  endOffset: number;
}

export const EventSummary = ({
  space,
  venue,
  startDate,
  startOffset,
  endOffset,
}: EventSummaryProps) => {
  const imageUrl = space?.images[0]?.url || venue?.images[0]?.url;
  const addressString = [
    venue?.address?.streetAddress1,
    venue?.address?.streetAddress2,
    venue?.address?.city,
    venue?.address?.state,
  ]
    .filter((part) => part)
    .join(', ');

  return (
    <>
      <SpaceDetailsContainer>
        <RoundedImageContainer>
          <SpaceImage src={imageUrl} alt="" />
        </RoundedImageContainer>
        <Box>
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontSize: {
                xs: '20px',
                sm: '22px',
              },
              lineHeight: '150%',
            }}
          >
            {venue?.name}
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 400,
              fontSize: {
                xs: '16px',
                sm: '20px',
              },
              lineHeight: '150%',
            }}
          >
            {space?.name}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: 'left',
              fontSize: {
                xs: '13px',
                sm: '16px',
              },
            }}
          >
            {addressString}
          </Typography>
        </Box>
      </SpaceDetailsContainer>
      <Divider />

      <Stack
        sx={{
          gap: {
            xs: '8px',
            sm: '16px',
          },
        }}
      >
        <EventInfoItem
          icon={<Calendar size={20} />}
          text={moment(startDate).format('MMM DD, YYYY')}
        />
        <EventInfoItem
          icon={<Clock size={20} />}
          text={`${offsetToTimeString(startOffset)} - ${offsetToTimeString(endOffset)}`}
        />
      </Stack>
    </>
  );
};
