import { compact, find, min, max } from 'lodash';

import type { OrganizationProfile } from '../../types/models/organization_types';
import { defaultCenter } from './addresses';
import type {
  PartialExpressBookSettings,
  HoursOfOperation,
  PartialVenue,
} from '../../types/models/venue_types';
import type { Maybe } from '../../types/common';

/*
 * Helpers.
 */

export const buildOrganizationCenterCoord = (
  organization: OrganizationProfile,
) => {
  if (!organization || !organization?.latitude || !organization?.longitude) {
    return defaultCenter;
  }

  return { lat: organization.latitude, lng: organization.longitude };
};

export const parseExpressBookSettingsFromVenues: (
  organization: OrganizationProfile,
) => PartialExpressBookSettings = (organization) => {
  const minLeadTimeDays: Maybe<number> = min(
    organization.venues?.map(
      (venue: PartialVenue) => venue.expressBookSettings?.minLeadTimeDays,
    ),
  );
  const maxLeadTimeDays: Maybe<number> = max(
    organization.venues?.map(
      (venue: PartialVenue) => venue.expressBookSettings?.maxLeadTimeDays,
    ),
  );
  const minEventDurationHours: Maybe<number> = min(
    organization.venues?.map(
      (venue: PartialVenue) => venue.expressBookSettings?.minEventDurationHours,
    ),
  );

  return {
    minLeadTimeDays,
    maxLeadTimeDays,
    minEventDurationHours,
  };
};

export const parseHoursOfOperationFromVenues: (
  organization: OrganizationProfile,
) => Maybe<HoursOfOperation> = (organization) => {
  const daysOfTheWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const hoursOfOperationFromVenues = compact(
    daysOfTheWeek.map((day: string) => {
      const isOpen: Maybe<boolean> = organization?.venues?.some(
        (venue: PartialVenue) =>
          find(venue.hoursOfOperation, { dayOfWeek: day }),
      );
      const startTime: Maybe<number> = min(
        organization.venues?.map(
          (venue: PartialVenue) =>
            find(venue.hoursOfOperation, { dayOfWeek: day })?.startTime,
        ),
      );
      const endTime: Maybe<number> = max(
        organization.venues?.map(
          (venue: PartialVenue) =>
            find(venue.hoursOfOperation, { dayOfWeek: day })?.endTime,
        ),
      );

      if (!startTime || !endTime || isOpen === undefined) {
        return undefined;
      }

      return {
        isOpen,
        startTime: startTime,
        endTime: endTime,
        dayOfWeek: day,
      };
    }),
  );

  return hoursOfOperationFromVenues.length === 7
    ? hoursOfOperationFromVenues
    : undefined;
};
