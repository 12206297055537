import React, { FC, useState } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';

import GoogleMapProvider from '../providers/GoogleMapProvider';

import { Space } from '../types/models/space_types';
import type { Procedure } from '../types/common';
import { SpaceCarousel } from './SpaceCarousel';

/*
 * Types.
 */

interface GoogleMapCoord {
  lat: number;
  lng: number;
}

interface MappableItem {
  key: string;
  name: string;
  position: GoogleMapCoord;
  spaces?: Space[];
  handleClick?: Procedure;
}

interface GoogleMapProps {
  mapStyle: { [key: string]: string };
  center: GoogleMapCoord;
  zoom?: number;
  mappableItems?: MappableItem[];
}

/*
 * Components.
 */

export const GoogleMap: FC<GoogleMapProps> = ({
  mapStyle,
  center,
  mappableItems = [],
  zoom,
}: GoogleMapProps) => {
  const [currentItem, setCurrentItem] = useState<MappableItem>();

  const handleLoad = (map: google.maps.Map) => {
    const bounds = new google.maps.LatLngBounds();

    mappableItems.forEach((mappableItem: MappableItem) =>
      bounds.extend({
        lat: mappableItem.position.lat,
        lng: mappableItem.position.lng,
      }),
    );

    // fitBounds is asynchronous so unfortunately the recommended way to adjust
    // zoom after fitting the bounds, is to set up a one time listener.
    google.maps.event.addListenerOnce(
      map,
      'bounds_changed',
      function (event: any) {
        // Zoom out once to remove any markers on the edges.
        const zoom = map.getZoom();

        if (zoom === undefined) {
          return;
        }

        // If there's only one item fitBounds zooms in way too much.
        if (mappableItems.length === 1 || zoom > 15) {
          map.setZoom(15);
          return;
        }

        // If there are too many items to bound, the zoom can go too far out.
        // in this case center to the US and just zoom a little in.
        if (zoom === 0) {
          map.setZoom(2);
          return;
        }

        // If the zoom is valid, zoom out once to make sure that no markers are on the edges.
        map.setZoom(zoom - 1);
      },
    );

    if (center) {
      map.setCenter(center);
    }
    map.fitBounds(bounds);
  };

  const handleMarkerClick = (mappableItem: MappableItem) => {
    const itemSpaces = mappableItem.spaces;
    if (itemSpaces && itemSpaces.length > 0) {
      setCurrentItem(mappableItem);
    } else if (mappableItem.handleClick) {
      mappableItem.handleClick();
    }
  };

  const handleCloseClick = () => {
    setCurrentItem(undefined);
  };

  if (!google) {
    return null;
  }

  return (
    <GoogleMapProvider
      containerStyle={mapStyle}
      center={center}
      zoom={zoom}
      handleLoad={handleLoad}
    >
      {mappableItems.map((mappableItem: MappableItem) => (
        <Marker
          key={mappableItem.key}
          title={mappableItem.name}
          icon="/map-pin.png"
          position={mappableItem.position}
          onClick={() => handleMarkerClick(mappableItem)}
        />
      ))}
      {currentItem && (
        <InfoWindow
          onCloseClick={() => handleCloseClick()}
          position={{
            lat: currentItem?.position.lat ?? 0,
            lng: currentItem?.position.lng ?? 0,
          }}
          options={{
            maxWidth: 262,
            pixelOffset: new google.maps.Size(0, -36),
          }}
        >
          <SpaceCarousel
            spaces={currentItem.spaces || []}
            handleClick={currentItem.handleClick}
          />
        </InfoWindow>
      )}
    </GoogleMapProvider>
  );
};
